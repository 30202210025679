import React, { useContext, useState } from "react";
import { classNames } from "../../Utils/Utils";
import Tooltip from "../UI/Tooltip/Tooltip";
import { GameEventsContext } from "../../contexts/GameEventContext";

function PlayerGeneral({ game, home_lineup, away_lineup }) {
  const { processedTotalEvent } = useContext(GameEventsContext);
  const [selectedTeam, setSelectedTeam] = useState(game?.home_team?._id);
  return (
    <div className="flex flex-col w-full mt-4">
      <div className="flex w-full">
        <div
          onClick={() => {
            setSelectedTeam(game?.home_team?._id);
          }}
          className={classNames(
            "cursor-pointer",
            "w-1/2",
            selectedTeam === game?.home_team?._id
              ? "border-green-500 text-green-600"
              : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
            "whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
          )}
        >
          {game?.home_team?.name}
        </div>

        <div
          onClick={() => {
            setSelectedTeam(game?.away_team?._id);
          }}
          className={classNames(
            "cursor-pointer",
            "w-1/2",
            selectedTeam === game?.away_team?._id
              ? "border-green-500 text-green-600"
              : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
            "whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
          )}
        >
          {game?.away_team?.name}
        </div>
      </div>
      <div className="flex">
        <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-950">
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th className="text-sm p-1 border-t border-b text-center min-w-10">
                <Tooltip
                  tip="Total dribbles"
                  className="flex w-full justify-center"
                >
                  D
                </Tooltip>
              </th>
              <th className="text-sm p-1 border-t border-b text-center min-w-10">
                <Tooltip
                  tip="Successful dribbles"
                  className="flex w-full justify-center"
                >
                  SD
                </Tooltip>
              </th>
              <th className="text-sm p-1 border-t border-b text-center min-w-10">
                <Tooltip
                  tip="Total Duels"
                  className="flex w-full justify-center"
                >
                  TD
                </Tooltip>
              </th>

              <th className="text-sm p-1 border-t border-b text-center min-w-10">
                <Tooltip tip="Duels won" className="flex w-full justify-center">
                  DW
                </Tooltip>
              </th>
              <th className="text-sm p-1 border-t border-b text-center min-w-10">
                <Tooltip
                  tip="Total aerial duels"
                  className="flex w-full justify-center"
                >
                  AD
                </Tooltip>
              </th>
              <th className="text-sm p-1 border-t border-b text-center min-w-10">
                <Tooltip
                  tip="Aerial duels won"
                  className="flex w-full justify-center"
                >
                  ADW
                </Tooltip>
              </th>
              <th className="text-sm p-1 border-t border-b text-center min-w-10">
                <Tooltip
                  tip="Possession won"
                  className="flex w-full justify-center"
                >
                  PW
                </Tooltip>
              </th>
              <th className="text-sm p-1 border-t border-b text-center min-w-10">
                <Tooltip
                  tip="Possession won in final 3rd"
                  className="flex w-full justify-center"
                >
                  PWFT
                </Tooltip>
              </th>
              <th className="text-sm p-1 border-t border-b text-center min-w-10">
                <Tooltip
                  tip="Possession lost"
                  className="flex w-full justify-center"
                >
                  PL
                </Tooltip>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white dark:bg-gray-900">
            <TableBody
              game={game}
              processedTotalEvent={processedTotalEvent}
              lineup={
                selectedTeam === game?.home_team?._id
                  ? home_lineup
                  : away_lineup
              }
              team={
                selectedTeam === game?.home_team?._id
                  ? game?.home_team
                  : game?.away_team
              }
            />
            <Totals
              team={
                selectedTeam === game?.home_team?._id
                  ? game?.home_team
                  : game?.away_team
              }
              lineup={
                selectedTeam === game?.home_team?._id
                  ? home_lineup
                  : away_lineup
              }
              processedTotalEvent={processedTotalEvent}
            />
          </tbody>
        </table>
      </div>
    </div>
  );
}

function TableBody({ team, lineup, processedTotalEvent, game }) {
  return lineup
    ?.map((player) => {
      let dribbles = processedTotalEvent?.dribble_events?.filter(
        (ev) => ev.playerId === player?.squad?.player?.opta_ID
      )?.length;

      let successful_dribbles = processedTotalEvent?.dribble_events?.filter(
        (ev) =>
          ev.playerId === player?.squad?.player?.opta_ID && ev.outcome === 1
      )?.length;

      let duels = processedTotalEvent?.duel_events?.filter(
        (ev) => ev.playerId === player?.squad?.player?.opta_ID
      )?.length;

      let duels_won = processedTotalEvent?.duel_events?.filter(
        (ev) =>
          ev.playerId === player?.squad?.player?.opta_ID && ev.outcome === 1
      )?.length;

      let aerial_duels = processedTotalEvent?.aerial_duel_events?.filter(
        (ev) => ev.playerId === player?.squad?.player?.opta_ID
      )?.length;

      let aerial_duels_won = processedTotalEvent?.aerial_duel_events?.filter(
        (ev) =>
          ev.playerId === player?.squad?.player?.opta_ID && ev.outcome === 1
      )?.length;

      let poss_won = processedTotalEvent?.poss_won_events?.filter(
        (ev) =>
          ev.playerId === player?.squad?.player?.opta_ID && ev.outcome === 1
      )?.length;

      let poss_won_final_3rd =
        processedTotalEvent?.poss_won_final_3rd_events?.filter(
          (ev) =>
            ev.playerId === player?.squad?.player?.opta_ID && ev.outcome === 1
        )?.length;

      let poss_lost = processedTotalEvent?.dispossessed_events?.filter(
        (ev) => ev.playerId === player?.squad?.player?.opta_ID
      )?.length;

      return {
        ...player,
        dribbles,
        successful_dribbles,
        duels,
        duels_won,
        aerial_duels,
        aerial_duels_won,
        poss_won,
        poss_won_final_3rd,
        poss_lost,
      };
    })
    ?.map((player, _, array) => {
      return (
        <Row
          player={player}
          team={game?.home_team}
          totals={array?.reduce(
            (acc, curr) => {
              acc.dribbles = acc.dribbles.filter((s) => s !== curr.dribbles);
              curr.dribbles > 0 && acc.dribbles.push(curr.dribbles);
              acc.dribbles = acc.dribbles.sort((a, b) => b - a);

              acc.successful_dribbles = acc.successful_dribbles.filter(
                (s) => s !== curr.successful_dribbles
              );
              curr.successful_dribbles > 0 &&
                acc.successful_dribbles.push(curr.successful_dribbles);
              acc.successful_dribbles = acc.successful_dribbles.sort(
                (a, b) => b - a
              );

              acc.duels = acc.duels.filter((s) => s !== curr.duels);
              curr.duels > 0 && acc.duels.push(curr.duels);
              acc.duels = acc.duels.sort((a, b) => b - a);

              acc.duels_won = acc.duels_won.filter((s) => s !== curr.duels_won);
              curr.duels_won > 0 && acc.duels_won.push(curr.duels_won);
              acc.duels_won = acc.duels_won.sort((a, b) => b - a);

              acc.aerial_duels = acc.aerial_duels.filter(
                (s) => s !== curr.aerial_duels
              );
              curr.aerial_duels > 0 && acc.aerial_duels.push(curr.aerial_duels);
              acc.aerial_duels = acc.aerial_duels.sort((a, b) => b - a);

              acc.aerial_duels_won = acc.aerial_duels_won.filter(
                (s) => s !== curr.aerial_duels_won
              );
              curr.aerial_duels_won > 0 &&
                acc.aerial_duels_won.push(curr.aerial_duels_won);
              acc.aerial_duels_won = acc.aerial_duels_won.sort((a, b) => b - a);

              acc.poss_won = acc.poss_won.filter((s) => s !== curr.poss_won);
              curr.poss_won > 0 && acc.poss_won.push(curr.poss_won);
              acc.poss_won = acc.poss_won.sort((a, b) => b - a);

              acc.poss_won_final_3rd = acc.poss_won_final_3rd.filter(
                (s) => s !== curr.poss_won_final_3rd
              );
              curr.poss_won_final_3rd > 0 &&
                acc.poss_won_final_3rd.push(curr.poss_won_final_3rd);
              acc.poss_won_final_3rd = acc.poss_won_final_3rd.sort(
                (a, b) => b - a
              );

              acc.poss_lost = acc.poss_lost.filter((s) => s !== curr.poss_lost);
              curr.poss_lost > 0 && acc.poss_lost.push(curr.poss_lost);
              acc.poss_lost = acc.poss_lost.sort((a, b) => b - a);

              return acc;
            },
            {
              dribbles: [],
              successful_dribbles: [],
              duels: [],
              duels_won: [],
              aerial_duels: [],
              aerial_duels_won: [],
              poss_won: [],
              poss_won_final_3rd: [],
              poss_lost: [],
            }
          )}
        />
      );
    });
}

function Row({ player, totals }) {
  return (
    <tr className="even:bg-gray-50 dark:even:bg-gray-800">
      <td className="p-1">
        {player?.squad?.shirt_number ?? player?.squad?.opta_shirt_number}
      </td>
      <td>
        <span className="text-gray-500">
          {player?.squad?.player?.first_name ??
            player?.squad?.player?.opta_first_name}
        </span>{" "}
        {player?.squad?.player?.last_name ??
          player?.squad?.player?.opta_last_name}
      </td>
      <td
        className={classNames(
          "text-center",
          totals?.dribbles?.findIndex((s) => s === player?.dribbles) === 0 &&
            "bg-green-400 dark:text-gray-900",
          totals?.dribbles?.findIndex((s) => s === player?.dribbles) === 1 &&
            "bg-green-300 dark:text-gray-900",
          totals?.dribbles?.findIndex((s) => s === player?.dribbles) === 2 &&
            "bg-green-200 dark:text-gray-900"
        )}
      >
        {player?.dribbles > 0 && player?.dribbles}
      </td>
      <td
        className={classNames(
          "text-center",
          totals?.successful_dribbles?.findIndex(
            (s) => s === player?.successful_dribbles
          ) === 0 && "bg-green-400 dark:text-gray-900",
          totals?.successful_dribbles?.findIndex(
            (s) => s === player?.successful_dribbles
          ) === 1 && "bg-green-300 dark:text-gray-900",
          totals?.successful_dribbles?.findIndex(
            (s) => s === player?.successful_dribbles
          ) === 2 && "bg-green-200 dark:text-gray-900"
        )}
      >
        {player?.successful_dribbles > 0 && player?.successful_dribbles}
      </td>
      <td
        className={classNames(
          "text-center",
          totals?.duels?.findIndex((s) => s === player?.duels) === 0 &&
            "bg-green-400 dark:text-gray-900",
          totals?.duels?.findIndex((s) => s === player?.duels) === 1 &&
            "bg-green-300 dark:text-gray-900",
          totals?.duels?.findIndex((s) => s === player?.duels) === 2 &&
            "bg-green-200 dark:text-gray-900"
        )}
      >
        {player?.duels > 0 && player?.duels}
      </td>
      <td
        className={classNames(
          "text-center",
          totals?.duels_won?.findIndex((s) => s === player?.duels_won) === 0 &&
            "bg-green-400 dark:text-gray-900",
          totals?.duels_won?.findIndex((s) => s === player?.duels_won) === 1 &&
            "bg-green-300 dark:text-gray-900",
          totals?.duels_won?.findIndex((s) => s === player?.duels_won) === 2 &&
            "bg-green-200 dark:text-gray-900"
        )}
      >
        {player?.duels_won > 0 && player?.duels_won}
      </td>
      <td
        className={classNames(
          "text-center",
          totals?.aerial_duels?.findIndex((s) => s === player?.aerial_duels) ===
            0 && "bg-green-400 dark:text-gray-900",
          totals?.aerial_duels?.findIndex((s) => s === player?.aerial_duels) ===
            1 && "bg-green-300 dark:text-gray-900",
          totals?.aerial_duels?.findIndex((s) => s === player?.aerial_duels) ===
            2 && "bg-green-200 dark:text-gray-900"
        )}
      >
        {player?.aerial_duels > 0 && player?.aerial_duels}
      </td>
      <td
        className={classNames(
          "text-center",
          totals?.aerial_duels_won?.findIndex(
            (s) => s === player?.aerial_duels_won
          ) === 0 && "bg-green-400 dark:text-gray-900",
          totals?.aerial_duels_won?.findIndex(
            (s) => s === player?.aerial_duels_won
          ) === 1 && "bg-green-300 dark:text-gray-900",
          totals?.aerial_duels_won?.findIndex(
            (s) => s === player?.aerial_duels_won
          ) === 2 && "bg-green-200 dark:text-gray-900"
        )}
      >
        {player?.aerial_duels_won > 0 && player?.aerial_duels_won}
      </td>
      <td
        className={classNames(
          "text-center",
          totals?.poss_won?.findIndex((s) => s === player?.poss_won) === 0 &&
            "bg-green-400 dark:text-gray-900",
          totals?.poss_won?.findIndex((s) => s === player?.poss_won) === 1 &&
            "bg-green-300 dark:text-gray-900",
          totals?.poss_won?.findIndex((s) => s === player?.poss_won) === 2 &&
            "bg-green-200 dark:text-gray-900"
        )}
      >
        {player?.poss_won > 0 && player?.poss_won}
      </td>
      <td
        className={classNames(
          "text-center",
          totals?.poss_won_final_3rd?.findIndex(
            (s) => s === player?.poss_won_final_3rd
          ) === 0 && "bg-green-400 dark:text-gray-900",
          totals?.poss_won_final_3rd?.findIndex(
            (s) => s === player?.passes_in_final_3rd
          ) === 1 && "bg-green-300 dark:text-gray-900",
          totals?.poss_won_final_3rd?.findIndex(
            (s) => s === player?.poss_won_final_3rd
          ) === 2 && "bg-green-200 dark:text-gray-900"
        )}
      >
        {player?.poss_won_final_3rd > 0 && player?.poss_won_final_3rd}
      </td>
      <td
        className={classNames(
          "text-center",
          totals?.poss_lost?.findIndex((s) => s === player?.poss_lost) === 0 &&
            "bg-green-400 dark:text-gray-900",
          totals?.poss_lost?.findIndex((s) => s === player?.poss_lost) === 1 &&
            "bg-green-300 dark:text-gray-900",
          totals?.poss_lost?.findIndex((s) => s === player?.poss_lost) === 2 &&
            "bg-green-200 dark:text-gray-900"
        )}
      >
        {player?.poss_lost > 0 && player?.poss_lost}
      </td>
    </tr>
  );
}
function Totals({ team, lineup, processedTotalEvent }) {
  let totals = lineup
    ?.map((player) => {
      let dribbles = processedTotalEvent?.dribble_events?.filter(
        (ev) => ev.playerId === player?.squad?.player?.opta_ID
      )?.length;

      let successful_dribbles = processedTotalEvent?.dribble_events?.filter(
        (ev) =>
          ev.playerId === player?.squad?.player?.opta_ID && ev.outcome === 1
      )?.length;

      let duels = processedTotalEvent?.duel_events?.filter(
        (ev) => ev.playerId === player?.squad?.player?.opta_ID
      )?.length;

      let duels_won = processedTotalEvent?.duel_events?.filter(
        (ev) =>
          ev.playerId === player?.squad?.player?.opta_ID && ev.outcome === 1
      )?.length;

      let aerial_duels = processedTotalEvent?.aerial_duel_events?.filter(
        (ev) => ev.playerId === player?.squad?.player?.opta_ID
      )?.length;

      let aerial_duels_won = processedTotalEvent?.aerial_duel_events?.filter(
        (ev) =>
          ev.playerId === player?.squad?.player?.opta_ID && ev.outcome === 1
      )?.length;

      let poss_won = processedTotalEvent?.poss_won_events?.filter(
        (ev) =>
          ev.playerId === player?.squad?.player?.opta_ID && ev.outcome === 1
      )?.length;

      let poss_won_final_3rd =
        processedTotalEvent?.poss_won_final_3rd_events?.filter(
          (ev) =>
            ev.playerId === player?.squad?.player?.opta_ID && ev.outcome === 1
        )?.length;

      let poss_lost = processedTotalEvent?.dispossessed_events?.filter(
        (ev) => ev.playerId === player?.squad?.player?.opta_ID
      )?.length;

      return {
        ...player,
        dribbles,
        successful_dribbles,
        duels,
        duels_won,
        aerial_duels,
        aerial_duels_won,
        poss_won,
        poss_won_final_3rd,
        poss_lost,
      };
    })
    ?.reduce(
      (acc, curr) => {
        acc.dribbles += curr.dribbles;
        acc.successful_dribbles += curr.successful_dribbles;
        acc.duels += curr.duels;
        acc.duels_won += curr.duels_won;
        acc.aerial_duels += curr.aerial_duels;
        acc.aerial_duels_won += curr.aerial_duels_won;
        acc.poss_won += curr.poss_won;
        acc.poss_won_final_3rd += curr.poss_won_final_3rd;
        acc.poss_lost += curr.poss_lost;
        return acc;
      },
      {
        dribbles: 0,
        successful_dribbles: 0,
        duels: 0,
        duels_won: 0,
        aerial_duels: 0,
        aerial_duels_won: 0,
        poss_won: 0,
        poss_won_final_3rd: 0,
        poss_lost: 0,
      }
    );

  return (
    <tr className="text-sm p-1 border-t border-b font-bold">
      <td className="p-1" colSpan={2}>
        Team totals:
      </td>

      <td className="text-center">
        {totals?.dribbles > 0 && totals?.dribbles}
      </td>
      <td className="text-center">
        {totals?.successful_dribbles > 0 && totals?.successful_dribbles}
      </td>
      <td className="text-center">{totals?.duels > 0 && totals?.duels}</td>
      <td className="text-center">
        {totals?.duels_won > 0 && totals?.duels_won}
      </td>
      <td className="text-center">
        {totals?.aerial_duels > 0 && totals?.aerial_duels}
      </td>
      <td className="text-center">
        {totals?.aerial_duels_won > 0 && totals?.aerial_duels_won}
      </td>
      <td className="text-center">
        {totals?.poss_won > 0 && totals?.poss_won}
      </td>
      <td className="text-center">
        {totals?.poss_won_final_3rd > 0 && totals?.poss_won_final_3rd}
      </td>
      <td className="text-center">
        {totals?.poss_lost > 0 && totals?.poss_lost}
      </td>
    </tr>
  );
}
export default PlayerGeneral;
