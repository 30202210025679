import React from "react";
import { classNames } from "../../../Utils/Utils";
export default function Panel({ children, className }) {
  return (
    <div
      className={classNames(
        "flex shadow-sm border-b border-gray-200 bg-white px-3 py-4 sm:px-6 m-2 rounded dark:border-none dark:bg-gray-900",
        className
      )}
    >
      {children}
    </div>
  );
}
