import React, { useContext, useState } from "react";
import { classNames } from "../../Utils/Utils";
import Tooltip from "../UI/Tooltip/Tooltip";
import { GameEventsContext } from "../../contexts/GameEventContext";

function PlayerDefence({ game, home_lineup, away_lineup }) {
  const { processedTotalEvent } = useContext(GameEventsContext);
  const [selectedTeam, setSelectedTeam] = useState(game?.home_team?._id);
  return (
    <div className="flex flex-col w-full mt-4">
      <div className="flex w-full">
        <div
          onClick={() => {
            setSelectedTeam(game?.home_team?._id);
          }}
          className={classNames(
            "cursor-pointer",
            "w-1/2",
            selectedTeam === game?.home_team?._id
              ? "border-green-500 text-green-600"
              : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
            "whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
          )}
        >
          {game?.home_team?.name}
        </div>

        <div
          onClick={() => {
            setSelectedTeam(game?.away_team?._id);
          }}
          className={classNames(
            "cursor-pointer",
            "w-1/2",
            selectedTeam === game?.away_team?._id
              ? "border-green-500 text-green-600"
              : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
            "whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
          )}
        >
          {game?.away_team?.name}
        </div>
      </div>
      <div className="flex">
        <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-950">
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th className="text-sm p-1 border-t border-b text-center min-w-10">
                <Tooltip tip="Tackles" className="flex w-full justify-center">
                  T
                </Tooltip>
              </th>
              <th className="text-sm p-1 border-t border-b text-center min-w-10">
                <Tooltip
                  tip="Tackles won"
                  className="flex w-full justify-center"
                >
                  TW
                </Tooltip>
              </th>
              <th className="text-sm p-1 border-t border-b text-center min-w-10">
                <Tooltip
                  tip="Interceptions"
                  className="flex w-full justify-center"
                >
                  I
                </Tooltip>
              </th>

              <th className="text-sm p-1 border-t border-b text-center min-w-10">
                <Tooltip
                  tip="Fouls committed"
                  className="flex w-full justify-center"
                >
                  FC
                </Tooltip>
              </th>
              <th className="text-sm p-1 border-t border-b text-center min-w-10">
                <Tooltip tip="Fouls won" className="flex w-full justify-center">
                  FW
                </Tooltip>
              </th>
              <th className="text-sm p-1 border-t border-b text-center min-w-10">
                <Tooltip
                  tip="Yellow cards"
                  className="flex w-full justify-center"
                >
                  YC
                </Tooltip>
              </th>
              <th className="text-sm p-1 border-t border-b text-center min-w-10">
                <Tooltip tip="Red cards" className="flex w-full justify-center">
                  RC
                </Tooltip>
              </th>
              <th className="text-sm p-1 border-t border-b text-center min-w-10">
                <Tooltip tip="Saves" className="flex w-full justify-center">
                  SV
                </Tooltip>
              </th>
              <th className="text-sm p-1 border-t border-b text-center min-w-10">
                <Tooltip
                  tip="Clearances"
                  className="flex w-full justify-center"
                >
                  CL
                </Tooltip>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white dark:bg-gray-900">
            <TableBody
              game={game}
              processedTotalEvent={processedTotalEvent}
              lineup={
                selectedTeam === game?.home_team?._id
                  ? home_lineup
                  : away_lineup
              }
              team={
                selectedTeam === game?.home_team?._id
                  ? game?.home_team
                  : game?.away_team
              }
            />
            <Totals
              team={
                selectedTeam === game?.home_team?._id
                  ? game?.home_team
                  : game?.away_team
              }
              lineup={
                selectedTeam === game?.home_team?._id
                  ? home_lineup
                  : away_lineup
              }
              processedTotalEvent={processedTotalEvent}
            />
          </tbody>
        </table>
      </div>
    </div>
  );
}

function TableBody({ team, lineup, processedTotalEvent, game }) {
  return lineup
    ?.map((player) => {
      let tackles = processedTotalEvent?.tackle_events?.filter(
        (ev) => ev.playerId === player?.squad?.player?.opta_ID
      )?.length;

      let tackles_won = processedTotalEvent?.tackle_events?.filter(
        (ev) =>
          ev.playerId === player?.squad?.player?.opta_ID && ev.outcome === 1
      )?.length;

      let interceptions = processedTotalEvent?.interception_events?.filter(
        (ev) => ev.playerId === player?.squad?.player?.opta_ID
      )?.length;

      let fouls = processedTotalEvent?.foul_events?.filter(
        (ev) =>
          ev.playerId === player?.squad?.player?.opta_ID && ev.outcome === 0
      )?.length;

      let fouls_won = processedTotalEvent?.foul_events?.filter(
        (ev) =>
          ev.playerId === player?.squad?.player?.opta_ID && ev.outcome === 1
      )?.length;

      let yellow_cards = processedTotalEvent?.yellow_card_events?.filter(
        (ev) => ev.playerId === player?.squad?.player?.opta_ID
      )?.length;

      let red_cards = processedTotalEvent?.red_card_events?.filter(
        (ev) => ev.playerId === player?.squad?.player?.opta_ID
      )?.length;

      let saves = processedTotalEvent?.events?.filter(
        (ev) =>
          ev.playerId === player?.squad?.player?.opta_ID && ev.typeId == 10
      )?.length;

      let clearances = processedTotalEvent?.clearance_events?.filter(
        (ev) => ev.playerId === player?.squad?.player?.opta_ID
      )?.length;

      return {
        ...player,
        tackles,
        tackles_won,
        interceptions,
        fouls,
        fouls_won,
        yellow_cards,
        red_cards,
        saves,
        clearances,
      };
    })
    ?.map((player, _, array) => {
      return (
        <Row
          player={player}
          team={game?.home_team}
          totals={array?.reduce(
            (acc, curr) => {
              acc.tackles = acc.tackles.filter((s) => s !== curr.tackles);
              curr.tackles > 0 && acc.tackles.push(curr.tackles);
              acc.tackles = acc.tackles.sort((a, b) => b - a);

              acc.tackles_won = acc.tackles_won.filter(
                (s) => s !== curr.tackles_won
              );
              curr.tackles_won > 0 && acc.tackles_won.push(curr.tackles_won);
              acc.tackles_won = acc.tackles_won.sort((a, b) => b - a);

              acc.interceptions = acc.interceptions.filter(
                (s) => s !== curr.interceptions
              );
              curr.interceptions > 0 &&
                acc.interceptions.push(curr.interceptions);
              acc.interceptions = acc.interceptions.sort((a, b) => b - a);

              acc.fouls = acc.fouls.filter((s) => s !== curr.fouls);
              curr.fouls > 0 && acc.fouls.push(curr.fouls);
              acc.fouls = acc.fouls.sort((a, b) => b - a);

              acc.fouls_won = acc.fouls_won.filter((s) => s !== curr.fouls_won);
              curr.fouls_won > 0 && acc.fouls_won.push(curr.fouls_won);
              acc.fouls_won = acc.fouls_won.sort((a, b) => b - a);

              acc.yellow_cards = acc.yellow_cards.filter(
                (s) => s !== curr.yellow_cards
              );
              curr.yellow_cards > 0 && acc.yellow_cards.push(curr.yellow_cards);
              acc.yellow_cards = acc.yellow_cards.sort((a, b) => b - a);

              acc.red_cards = acc.red_cards.filter((s) => s !== curr.red_cards);
              curr.red_cards > 0 && acc.red_cards.push(curr.red_cards);
              acc.red_cards = acc.red_cards.sort((a, b) => b - a);

              acc.saves = acc.saves.filter((s) => s !== curr.saves);
              curr.saves > 0 && acc.saves.push(curr.saves);
              acc.saves = acc.saves.sort((a, b) => b - a);

              acc.clearances = acc.clearances.filter(
                (s) => s !== curr.clearances
              );
              curr.clearances > 0 && acc.clearances.push(curr.clearances);
              acc.clearances = acc.clearances.sort((a, b) => b - a);

              return acc;
            },
            {
              tackles: [],
              tackles_won: [],
              interceptions: [],
              fouls: [],
              fouls_won: [],
              yellow_cards: [],
              red_cards: [],
              saves: [],
              clearances: [],
            }
          )}
        />
      );
    });
}

function Row({ player, totals }) {
  return (
    <tr className="even:bg-gray-50 dark:even:bg-gray-800">
      <td className="p-1">
        {player?.squad?.shirt_number ?? player?.squad?.opta_shirt_number}
      </td>
      <td>
        <span className="text-gray-500">
          {player?.squad?.player?.first_name ??
            player?.squad?.player?.opta_first_name}
        </span>{" "}
        {player?.squad?.player?.last_name ??
          player?.squad?.player?.opta_last_name}
      </td>
      <td
        className={classNames(
          "text-center",
          totals?.tackles?.findIndex((s) => s === player?.tackles) === 0 &&
            "bg-green-400 dark:text-gray-900",
          totals?.tackles?.findIndex((s) => s === player?.tackles) === 1 &&
            "bg-green-300 dark:text-gray-900",
          totals?.tackles?.findIndex((s) => s === player?.tackles) === 2 &&
            "bg-green-200 dark:text-gray-900"
        )}
      >
        {player?.tackles > 0 && player?.tackles}
      </td>
      <td
        className={classNames(
          "text-center",
          totals?.tackles_won?.findIndex((s) => s === player?.tackles_won) ===
            0 && "bg-green-400 dark:text-gray-900",
          totals?.tackles_won?.findIndex((s) => s === player?.tackles_won) ===
            1 && "bg-green-300 dark:text-gray-900",
          totals?.tackles_won?.findIndex((s) => s === player?.tackles_won) ===
            2 && "bg-green-200 dark:text-gray-900"
        )}
      >
        {player?.tackles_won > 0 && player?.tackles_won}
      </td>
      <td
        className={classNames(
          "text-center",
          totals?.interceptions?.findIndex(
            (s) => s === player?.interceptions
          ) === 0 && "bg-green-400 dark:text-gray-900",
          totals?.interceptions?.findIndex(
            (s) => s === player?.interceptions
          ) === 1 && "bg-green-300 dark:text-gray-900",
          totals?.interceptions?.findIndex(
            (s) => s === player?.interceptions
          ) === 2 && "bg-green-200 dark:text-gray-900"
        )}
      >
        {player?.interceptions > 0 && player?.interceptions}
      </td>
      <td
        className={classNames(
          "text-center",
          totals?.fouls?.findIndex((s) => s === player?.fouls) === 0 &&
            "bg-green-400 dark:text-gray-900",
          totals?.fouls?.findIndex((s) => s === player?.fouls) === 1 &&
            "bg-green-300 dark:text-gray-900",
          totals?.fouls?.findIndex((s) => s === player?.fouls) === 2 &&
            "bg-green-200 dark:text-gray-900"
        )}
      >
        {player?.fouls > 0 && player?.fouls}
      </td>
      <td
        className={classNames(
          "text-center",
          totals?.fouls_won?.findIndex((s) => s === player?.fouls_won) === 0 &&
            "bg-green-400 dark:text-gray-900",
          totals?.fouls_won?.findIndex((s) => s === player?.fouls_won) === 1 &&
            "bg-green-300 dark:text-gray-900",
          totals?.fouls_won?.findIndex((s) => s === player?.fouls_won) === 2 &&
            "bg-green-200 dark:text-gray-900"
        )}
      >
        {player?.fouls_won > 0 && player?.fouls_won}
      </td>
      <td
        className={classNames(
          "text-center",
          totals?.yellow_cards?.findIndex((s) => s === player?.yellow_cards) ===
            0 && "bg-green-400 dark:text-gray-900",
          totals?.yellow_cards?.findIndex((s) => s === player?.yellow_cards) ===
            1 && "bg-green-300 dark:text-gray-900",
          totals?.yellow_cards?.findIndex((s) => s === player?.yellow_cards) ===
            2 && "bg-green-200 dark:text-gray-900"
        )}
      >
        {player?.yellow_cards > 0 && player?.yellow_cards}
      </td>
      <td
        className={classNames(
          "text-center",
          totals?.red_cards?.findIndex((s) => s === player?.red_cards) === 0 &&
            "bg-green-400 dark:text-gray-900",
          totals?.red_cards?.findIndex((s) => s === player?.red_cards) === 1 &&
            "bg-green-300 dark:text-gray-900",
          totals?.red_cards?.findIndex((s) => s === player?.red_cards) === 2 &&
            "bg-green-200 dark:text-gray-900"
        )}
      >
        {player?.red_cards > 0 && player?.red_cards}
      </td>
      <td
        className={classNames(
          "text-center",
          totals?.saves?.findIndex((s) => s === player?.saves) === 0 &&
            "bg-green-400 dark:text-gray-900",
          totals?.saves?.findIndex((s) => s === player?.passes_in_final_3rd) ===
            1 && "bg-green-300 dark:text-gray-900",
          totals?.saves?.findIndex((s) => s === player?.saves) === 2 &&
            "bg-green-200 dark:text-gray-900"
        )}
      >
        {player?.saves > 0 && player?.saves}
      </td>
      <td
        className={classNames(
          "text-center",
          totals?.clearances?.findIndex((s) => s === player?.clearances) ===
            0 && "bg-green-400 dark:text-gray-900",
          totals?.clearances?.findIndex((s) => s === player?.clearances) ===
            1 && "bg-green-300 dark:text-gray-900",
          totals?.clearances?.findIndex((s) => s === player?.clearances) ===
            2 && "bg-green-200 dark:text-gray-900"
        )}
      >
        {player?.clearances > 0 && player?.clearances}
      </td>
    </tr>
  );
}
function Totals({ team, lineup, processedTotalEvent }) {
  let totals = lineup
    ?.map((player) => {
      let tackles = processedTotalEvent?.tackle_events?.filter(
        (ev) => ev.playerId === player?.squad?.player?.opta_ID
      )?.length;

      let tackles_won = processedTotalEvent?.tackle_events?.filter(
        (ev) =>
          ev.playerId === player?.squad?.player?.opta_ID && ev.outcome === 1
      )?.length;

      let interceptions = processedTotalEvent?.interception_events?.filter(
        (ev) => ev.playerId === player?.squad?.player?.opta_ID
      )?.length;

      let fouls = processedTotalEvent?.foul_events?.filter(
        (ev) =>
          ev.playerId === player?.squad?.player?.opta_ID && ev.outcome === 0
      )?.length;

      let fouls_won = processedTotalEvent?.foul_events?.filter(
        (ev) =>
          ev.playerId === player?.squad?.player?.opta_ID && ev.outcome === 1
      )?.length;

      let yellow_cards = processedTotalEvent?.yellow_card_events?.filter(
        (ev) => ev.playerId === player?.squad?.player?.opta_ID
      )?.length;

      let red_cards = processedTotalEvent?.red_card_events?.filter(
        (ev) => ev.playerId === player?.squad?.player?.opta_ID
      )?.length;

      let saves = processedTotalEvent?.events?.filter(
        (ev) =>
          ev.playerId === player?.squad?.player?.opta_ID && ev.typeId === 10
      )?.length;

      let clearances = processedTotalEvent?.clearance_events?.filter(
        (ev) => ev.playerId === player?.squad?.player?.opta_ID
      )?.length;

      return {
        ...player,
        tackles,
        tackles_won,
        interceptions,
        fouls,
        fouls_won,
        yellow_cards,
        red_cards,
        saves,
        clearances,
      };
    })
    ?.reduce(
      (acc, curr) => {
        acc.tackles += curr.tackles;
        acc.tackles_won += curr.tackles_won;
        acc.interceptions += curr.interceptions;
        acc.fouls += curr.fouls;
        acc.fouls_won += curr.fouls_won;
        acc.yellow_cards += curr.yellow_cards;
        acc.red_cards += curr.red_cards;
        acc.saves += curr.saves;
        acc.clearances += curr.clearances;
        return acc;
      },
      {
        tackles: 0,
        tackles_won: 0,
        interceptions: 0,
        fouls: 0,
        fouls_won: 0,
        yellow_cards: 0,
        red_cards: 0,
        saves: 0,
        clearances: 0,
      }
    );

  return (
    <tr className="text-sm p-1 border-t border-b font-bold">
      <td className="p-1" colSpan={2}>
        Team totals:
      </td>

      <td className="text-center">{totals?.tackles > 0 && totals?.tackles}</td>
      <td className="text-center">
        {totals?.tackles_won > 0 && totals?.tackles_won}
      </td>
      <td className="text-center">
        {totals?.interceptions > 0 && totals?.interceptions}
      </td>
      <td className="text-center">{totals?.fouls > 0 && totals?.fouls}</td>
      <td className="text-center">
        {totals?.fouls_won > 0 && totals?.fouls_won}
      </td>
      <td className="text-center">
        {totals?.yellow_cards > 0 && totals?.yellow_cards}
      </td>
      <td className="text-center">
        {totals?.red_cards > 0 && totals?.red_cards}
      </td>
      <td className="text-center">{totals?.saves > 0 && totals?.saves}</td>
      <td className="text-center">
        {totals?.clearances > 0 && totals?.clearances}
      </td>
    </tr>
  );
}
export default PlayerDefence;
