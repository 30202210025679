/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";
// import styled from "styled-components";
import { useHistory } from "react-router-dom";
import moment from "moment";
// import { ScrollBar } from "../../Theme/Hyper";
import { UIContext, MODE_CONSTS } from "../../contexts/UIContext";
import CalendarUI from "../UI/Calendar/CalendarUI";
// import "../../calendar.css";
import gql from "graphql-tag";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { GameEventsContext } from "../../contexts/GameEventContext";

const GAMES_BY_DATE = gql`
  query Games($date: String) {
    gamesByDate(date: $date) {
      _id
      time
      description
      date
      competition {
        _id
        name
        badge
        order
        competition_type
        colour
      }
      home_team {
        _id
        name
        short_name
        code
        variables
      }
      away_team {
        _id
        name
        short_name
        code
        variables
      }
    }
  }
`;

const GAMES_BY_MONTH = gql`
  query Games($date: String) {
    gamesByMonth(date: $date) {
      _id
      date
    }
  }
`;

// const Main = styled.div`
//   display: flex;
//   box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
//   width: 100%;
//   height: 100%;
// `;
// const GamesDiv = styled(ScrollBar)`
//   display: flex;
//   flex-direction: column;
//   width: 100%;
//   padding: 1em;
//   box-sizing: border-box;
//   overflow-y: auto;
//   height: 95vh;
// `;
// const Game = styled.div`
//   display: flex;
//   width: 100%;
//   padding: 0.5em;
//   justify-content: center;
//   font-size: 1em;
//   font-weight: 100;
//   align-items: center;
//   box-sizing: border-box;
//   background-color: ${(props) =>
//     props.sub ? " #181d25" : props.selected ? "#db0a41" : "#202731"};
//   border-bottom: 2px solid #181d25;
//   cursor: ${(props) => (props.clickable ? "pointer" : "")};
//   :hover {
//     ${(props) => !props.noHover && !props.selected && { color: "#d11e42" }}
//   }
// `;
// const Team = styled.div`
//   display: flex;
//   margin: 0.5em;
//   width: 45%;
//   justify-content: ${(props) => (props.right ? "flex-end" : "")};
// `;
// const Verses = styled.div`
//   width: 10%;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// `;
// const Competitions = styled.div`
//   display: flex;
//   flex-direction: column;
//   background-color: #202731;
//   margin-left: 1px;
//   width: 300px;
//   padding: 1em;
// `;
// const Column = styled.div`
//   display: flex;
//   flex-direction: column;
//   width: 100%;
//   box-sizing: border-box;
// `;

export default function Games({ onSelectGame }) {
  const {
    setHomeScorers,
    setAwayScorers,
    setHomePenalties,
    setAwayPenalties,
    setPeriods,
  } = useContext(UIContext);
  const location = useLocation();
  const history = useHistory();
  const [fixtures, setFixtures] = useState([]);
  const { setEvents } = useContext(GameEventsContext);
  const [selectedMonth, setSelectedMonth] = useState(moment());
  const [monthFixtures, setMonthFixtures] = useState();
  const [getMonthFixtures, { data: month_data }] = useLazyQuery(
    GAMES_BY_MONTH,
    {
      fetchPolicy: "network-only",
    }
  );
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [momentDate, setMomentDate] = useState(moment());
  //   // TODO - ADDD LOADING AND ERROR STATES
  const [getFixtures, { data }] = useLazyQuery(GAMES_BY_DATE);
  useEffect(() => {
    setHomeScorers([]);
    setAwayScorers([]);
    setHomePenalties([]);
    setAwayPenalties([]);
    setPeriods([]);
    setEvents([]);
  }, []);
  useEffect(() => {
    if (date) {
      getFixtures({ variables: { date: date + "Z" } });
    }
  }, [date, getFixtures]);
  useEffect(() => {
    if (data) {
      setFixtures([...data.gamesByDate]);
    }
  }, [data]);
  useEffect(() => {
    if (selectedMonth) {
      getMonthData();
    }
  }, [selectedMonth, getMonthFixtures]);
  useEffect(() => {
    if (month_data) {
      setMonthFixtures([...(month_data?.gamesByMonth || [])]);
    }
  }, [month_data]);
  function getMonthData() {
    getMonthFixtures({
      variables: { date: selectedMonth.format("YYYY-MM-DD") + "Z" },
    });
  }
  return (
    <div class="min-h-full flex w-[calc(100vw-80px)]">
      <div class="w-[400px] border-r-2 border-r-gray-100 p-4 h-[calc(100vh-56px)]">
        <CalendarUI
          onChangeDate={(date) => {
            setDate(moment(date).format("YYYY-MM-DD"));
            setMomentDate(moment(date));
            setSelectedMonth(moment(date));
          }}
          date={momentDate}
          highlights={monthFixtures?.map((m) => m.date)}
        />
      </div>
      <div class="flex flex-col w-full bg-gray-50 p-5 dark:bg-gray-900">
        <ul
          role="list"
          className="h-[calc(100vh-96px)] overflow-y-scroll divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl"
        >
          {fixtures
            ?.sort((a, b) => {
              if (a.competition?.order > b.competition?.order) {
                return 1;
              } else if (a.competition?.order < b.competition?.order) {
                return -1;
              }
              if (a.competition?.name > b.competition?.name) {
                return 1;
              } else if (a.competition?.name < b.competition?.name) {
                return -1;
              }
              let a_date = moment(a.date + " " + a.time);
              let b_date = moment(b.date + " " + b.time);
              // Else go to the 2nd item
              if (a_date < b_date) {
                return -1;
              } else if (a_date > b_date) {
                return 1;
              } else {
                // nothing to split them
                if (a?.home_team?.name < b?.home_team?.name) {
                  return -1;
                }
                if (a?.home_team?.name > b?.home_team?.name) {
                  return 1;
                }
                return 0;
              }
            })
            ?.map((fixture, index) => {
              return (
                <>
                  {(index === 0 ||
                    fixtures[index - 1].competition._id !==
                      fixture.competition._id) && (
                    <li>
                      <div className="flex text-sm p-2">
                        {fixture.competition?.name}
                      </div>
                    </li>
                  )}
                  <li
                    key={fixture._id}
                    className="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6"
                    onClick={() => {
                      onSelectGame(fixture);
                      let path = location;
                      let url =
                        location.pathname.replaceAll("/", "") +
                        "/" +
                        fixture._id;
                      if (path.pathname === "/efl/") {
                        url =
                          "/" +
                          location.pathname.replaceAll("/", "") +
                          "/" +
                          fixture._id;
                      }
                      history.push({
                        pathname: url,
                      });
                    }}
                  >
                    <div class="w-full text-right">
                      {fixture.home_team?.name}
                    </div>

                    <div class="flex justify-center">
                      {moment(fixture?.time, "HH:mmZ")?.format("HH:mm")}
                    </div>

                    <div class="w-full text-left">
                      {fixture.away_team?.name}
                    </div>
                  </li>
                </>
              );
            })}
        </ul>
      </div>
    </div>
  );
}
