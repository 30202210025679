import React from "react";

function Insights({ game }) {
  return (
    <div className="flex flex-col w-full mt-4">
      <div className="flex flex-col w-full h-[calc(100vh-300px)] overflow-y-auto">
        {game?.match_facts?.map((fact) => {
          return (
            <div className="flex border-b align-middle p-4">
              <div className="flex w-full">{fact?.fact}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Insights;
