import React from "react";
import Pitch from "../../Images/LargePitch.png";
import {
  Assist,
  Goal,
  SubOff,
  SubOn,
  YellowCard,
} from "../EventIcons/EventIcons";
function Formation({
  home_team,
  away_team,
  home_lineup,
  away_lineup,
  home_formation,
  away_formation,
  goals,
  cards,
  subs,
  assists,
}) {
  return (
    <div className="justify-center flex p-10">
      <div className="relative w-full">
        <img
          src={Pitch}
          className="w-full filter invert-[40%] sepia-[14%] saturate-[391%]"
        />
        {home_formation?.positions?.map((pos, index) => {
          let xScale = 7.8 / 1.5;
          let yScale = 3.3 / 1.5;

          let y_scale = 315;
          let x_scale = 620;
          let width = 40;
          let height = 40;

          let x = pos.x * -xScale + 300;
          let y = pos.y * -yScale + 189;

          let player = home_lineup?.find((l) => l.order === index + 1);
          let y_perc = ((y / y_scale) * 100) / 2;
          let x_perc = (x / x_scale) * 100;
          return (
            <div
              className="absolute flex flex-col items-center -translate-x-1/2 -translate-y-4 z-0"
              style={{
                top: "calc(" + x_perc + "%)",
                left: "calc(" + y_perc + "%)",
              }}
            >
              <div
                className={`rounded-full bg-gray-300 origin-center justify-center items-center flex flex-col border z-0`}
                style={{
                  backgroundColor: home_team?.variables?.home_kit_bg,
                  color: home_team?.variables?.home_kit_text,
                  height: height + "px",
                  width: width + "px",
                }}
              >
                {/* <div>{player?.squad?.player?.opta_last_name}</div>*/}
                <div>
                  {player?.squad?.shirt_number ??
                    player?.squad?.opta_shirt_number}
                </div>
                {/* <div>{parseInt(y)}</div> */}
              </div>
              <div className="flex text-sm text-nowrap">
                {player?.squad?.player?.opta_last_name}
              </div>

              <div className="flex">
                {goals
                  ?.filter((g) => {
                    return (
                      (g?.player || g?.playerId) &&
                      (g?.player === player?.squad?._id ||
                        g?.playerId === player?.squad?.player?.opta_ID)
                    );
                  })
                  ?.map((goal) => {
                    return <Goal goal={goal} />;
                  })}
                {cards?.map((card) => {
                  return (
                    card.playerId === player?.squad?.player?.opta_ID &&
                    card.qualifier.find((q) => q.qualifierId === 31) !==
                      undefined && <YellowCard card={card} />
                  );
                })}

                {subs?.findLastIndex(
                  (sub) =>
                    sub.playerId === player?.squad?.player?.opta_ID &&
                    sub.typeId === 19
                ) >
                  subs?.findLastIndex(
                    (sub) =>
                      sub.playerId === player?.squad?.player?.opta_ID &&
                      sub.typeId === 18
                  ) && (
                  <SubOn
                    subOn={subs?.find(
                      (sub) =>
                        sub.playerId === player?.squad?.player?.opta_ID &&
                        sub.typeId === 19
                    )}
                    subOff={
                      subs?.find(
                        (sub) =>
                          sub.playerId === player?.squad?.player?.opta_ID &&
                          sub.typeId === 19
                      ).related_event
                    }
                  />
                )}
                {subs?.findLastIndex(
                  (sub) =>
                    sub.playerId === player?.squad?.player?.opta_ID &&
                    sub.typeId === 19
                ) <
                  subs?.findLastIndex(
                    (sub) =>
                      sub.playerId === player?.squad?.player?.opta_ID &&
                      sub.typeId === 18
                  ) && (
                  <SubOff
                    subOff={subs?.find(
                      (sub) =>
                        sub.playerId === player?.squad?.player?.opta_ID &&
                        sub.typeId === 19
                    )}
                    subOn={
                      subs?.find(
                        (sub) =>
                          sub.playerId === player?.squad?.player?.opta_ID &&
                          sub.typeId === 19
                      ).related_event
                    }
                  />
                )}
                {assists
                  ?.filter((assist) => {
                    return assist?.playerId === player?.squad?.player?.opta_ID;
                  })
                  ?.map((assist) => {
                    return (
                      <>
                        <Assist assist={assist} />
                      </>
                    );
                  })}
              </div>
            </div>
          );
        })}
        {away_formation?.positions?.map((pos, index) => {
          let xScale = 7.8 / 1.5;
          let yScale = 3.3 / 1.5;

          let y_scale = 315;
          let x_scale = 620;
          let width = 40;
          let height = 40;

          let x = pos.x * xScale + 300;
          let y = pos.y * yScale + 189 + 240;

          let player = away_lineup?.find((l) => l.order === index + 1);
          let y_perc = ((y / y_scale) * 100) / 2;
          let x_perc = (x / x_scale) * 100;
          return (
            <div
              className="absolute flex flex-col items-center -translate-x-1/2 -translate-y-4 z-0"
              style={{
                top: "calc(" + x_perc + "%)",
                left: "calc(" + y_perc + "%)",
              }}
            >
              <div
                className={`rounded-full bg-gray-300 origin-center justify-center items-center flex flex-col border z-0`}
                style={{
                  backgroundColor: away_team?.variables?.home_kit_bg,
                  color: away_team?.variables?.home_kit_text,
                  height: height + "px",
                  width: width + "px",
                }}
              >
                {/* <div>{player?.squad?.player?.opta_last_name}</div>*/}
                <div>
                  {player?.squad?.shirt_number ??
                    player?.squad?.opta_shirt_number}
                </div>
                {/* <div>{parseInt(y)}</div> */}
              </div>
              <div className="flex text-sm text-nowrap">
                {player?.squad?.player?.opta_last_name}
              </div>
              <div className="flex">
                {goals
                  ?.filter((g) => {
                    return (
                      (g?.player || g?.playerId) &&
                      (g?.player === player?.squad?._id ||
                        g?.playerId === player?.squad?.player?.opta_ID)
                    );
                  })
                  ?.map((goal) => {
                    return <Goal goal={goal} />;
                  })}
                {cards?.map((card) => {
                  return (
                    card.playerId === player?.squad?.player?.opta_ID &&
                    card.qualifier.find((q) => q.qualifierId === 31) !==
                      undefined && <YellowCard card={card} />
                  );
                })}

                {subs?.findLastIndex(
                  (sub) =>
                    sub.playerId === player?.squad?.player?.opta_ID &&
                    sub.typeId === 19
                ) >
                  subs?.findLastIndex(
                    (sub) =>
                      sub.playerId === player?.squad?.player?.opta_ID &&
                      sub.typeId === 18
                  ) && (
                  <SubOn
                    subOn={subs?.find(
                      (sub) =>
                        sub.playerId === player?.squad?.player?.opta_ID &&
                        sub.typeId === 19
                    )}
                    subOff={
                      subs?.find(
                        (sub) =>
                          sub.playerId === player?.squad?.player?.opta_ID &&
                          sub.typeId === 19
                      ).related_event
                    }
                  />
                )}
                {subs?.findLastIndex(
                  (sub) =>
                    sub.playerId === player?.squad?.player?.opta_ID &&
                    sub.typeId === 19
                ) <
                  subs?.findLastIndex(
                    (sub) =>
                      sub.playerId === player?.squad?.player?.opta_ID &&
                      sub.typeId === 18
                  ) && (
                  <SubOff
                    subOff={subs?.find(
                      (sub) =>
                        sub.playerId === player?.squad?.player?.opta_ID &&
                        sub.typeId === 19
                    )}
                    subOn={
                      subs?.find(
                        (sub) =>
                          sub.playerId === player?.squad?.player?.opta_ID &&
                          sub.typeId === 19
                      ).related_event
                    }
                  />
                )}
                {assists
                  ?.filter((assist) => {
                    return assist?.playerId === player?.squad?.player?.opta_ID;
                  })
                  ?.map((assist) => {
                    return (
                      <>
                        <Assist assist={assist} />
                      </>
                    );
                  })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Formation;
