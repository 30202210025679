import React, { useContext, useState } from "react";
import { classNames } from "../../Utils/Utils";
import Tooltip from "../UI/Tooltip/Tooltip";
import { GameEventsContext } from "../../contexts/GameEventContext";

function PlayerDistribution({ game, home_lineup, away_lineup }) {
  const { processedTotalEvent } = useContext(GameEventsContext);
  const [selectedTeam, setSelectedTeam] = useState(game?.home_team?._id);
  return (
    <div className="flex flex-col w-full mt-4">
      <div className="flex w-full">
        <div
          onClick={() => {
            setSelectedTeam(game?.home_team?._id);
          }}
          className={classNames(
            "cursor-pointer",
            "w-1/2",
            selectedTeam === game?.home_team?._id
              ? "border-green-500 text-green-600"
              : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
            "whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
          )}
        >
          {game?.home_team?.name}
        </div>

        <div
          onClick={() => {
            setSelectedTeam(game?.away_team?._id);
          }}
          className={classNames(
            "cursor-pointer",
            "w-1/2",
            selectedTeam === game?.away_team?._id
              ? "border-green-500 text-green-600"
              : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
            "whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
          )}
        >
          {game?.away_team?.name}
        </div>
      </div>
      <div className="flex">
        <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-950">
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th className="text-sm p-1 border-t border-b text-center min-w-10">
                <Tooltip tip="Assists" className="flex w-full justify-center">
                  A
                </Tooltip>
              </th>
              <th className="text-sm p-1 border-t border-b text-center min-w-10">
                <Tooltip
                  tip="Chances created"
                  className="flex w-full justify-center"
                >
                  CC
                </Tooltip>
              </th>
              <th className="text-sm p-1 border-t border-b text-center min-w-10">
                <Tooltip tip="Touches" className="flex w-full justify-center">
                  T
                </Tooltip>
              </th>

              <th className="text-sm p-1 border-t border-b text-center min-w-10">
                <Tooltip
                  tip="Total passes"
                  className="flex w-full justify-center"
                >
                  TP
                </Tooltip>
              </th>
              <th className="text-sm p-1 border-t border-b text-center min-w-10">
                <Tooltip
                  tip="Accurate passes"
                  className="flex w-full justify-center"
                >
                  AP
                </Tooltip>
              </th>
              <th className="text-sm p-1 border-t border-b text-center min-w-10">
                <Tooltip
                  tip="Passing accuracy"
                  className="flex w-full justify-center"
                >
                  PA%
                </Tooltip>
              </th>
              <th className="text-sm p-1 border-t border-b text-center min-w-10">
                <Tooltip
                  tip="Passes in final 3rd"
                  className="flex w-full justify-center"
                >
                  PiFT
                </Tooltip>
              </th>
              <th className="text-sm p-1 border-t border-b text-center min-w-10">
                <Tooltip
                  tip="Passing accuracy in final 3rd"
                  className="flex w-full justify-center"
                >
                  PAFT%
                </Tooltip>
              </th>
              <th className="text-sm p-1 border-t border-b text-center min-w-10">
                <Tooltip
                  tip="Total crosses"
                  className="flex w-full justify-center"
                >
                  TC
                </Tooltip>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white dark:bg-gray-900">
            <TableBody
              game={game}
              processedTotalEvent={processedTotalEvent}
              lineup={
                selectedTeam === game?.home_team?._id
                  ? home_lineup
                  : away_lineup
              }
              team={
                selectedTeam === game?.home_team?._id
                  ? game?.home_team
                  : game?.away_team
              }
            />
            <Totals
              team={
                selectedTeam === game?.home_team?._id
                  ? game?.home_team
                  : game?.away_team
              }
              lineup={
                selectedTeam === game?.home_team?._id
                  ? home_lineup
                  : away_lineup
              }
              processedTotalEvent={processedTotalEvent}
            />
          </tbody>
        </table>
      </div>
    </div>
  );
}

function TableBody({ team, lineup, processedTotalEvent, game }) {
  return lineup
    ?.map((player) => {
      let assists = processedTotalEvent?.assist_events?.filter(
        (ev) => ev.playerId === player?.squad?.player?.opta_ID
      )?.length;

      let touches = processedTotalEvent?.touch_events?.filter(
        (ev) => ev.playerId === player?.squad?.player?.opta_ID
      )?.length;

      let passes = processedTotalEvent?.pass_events?.filter(
        (ev) => ev.playerId === player?.squad?.player?.opta_ID
      )?.length;

      let chances_created = processedTotalEvent?.events?.filter(
        (ev) =>
          ev.playerId === player?.squad?.player?.opta_ID &&
          ev.qualifier?.findIndex((q) => q.qualifierId === 154) > -1
      )?.length;

      let accurate_passes = processedTotalEvent?.pass_events?.filter(
        (ev) =>
          ev.playerId === player?.squad?.player?.opta_ID && ev.outcome === 1
      )?.length;

      let accurate_passes_percentage = (
        (accurate_passes / passes) *
        100
      ).toFixed(1);

      let passes_in_final_3rd =
        processedTotalEvent?.passes_in_final_3rd_events?.filter(
          (ev) => ev.playerId === player?.squad?.player?.opta_ID
        )?.length;

      let passes_in_final_3rd_success =
        processedTotalEvent?.passes_in_final_3rd_events?.filter(
          (ev) =>
            ev.playerId === player?.squad?.player?.opta_ID && ev.outcome === 1
        )?.length;

      let accurate_passes_in_final_3rd_percentage = (
        (passes_in_final_3rd_success / passes_in_final_3rd) *
        100
      ).toFixed(1);

      let crosses = processedTotalEvent?.cross_events?.filter(
        (ev) => ev.playerId === player?.squad?.player?.opta_ID
      )?.length;

      return {
        ...player,
        assists,
        touches,
        passes,
        chances_created,
        accurate_passes,
        accurate_passes_percentage,
        passes_in_final_3rd,
        accurate_passes_in_final_3rd_percentage,
        crosses,
      };
    })
    ?.map((player, _, array) => {
      return (
        <Row
          player={player}
          team={game?.home_team}
          totals={array?.reduce(
            (acc, curr) => {
              acc.assists = acc.assists.filter((s) => s !== curr.assists);
              curr.assists > 0 && acc.assists.push(curr.assists);
              acc.assists = acc.assists.sort((a, b) => b - a);

              acc.touches = acc.touches.filter((s) => s !== curr.touches);
              curr.touches > 0 && acc.touches.push(curr.touches);
              acc.touches = acc.touches.sort((a, b) => b - a);

              acc.passes = acc.passes.filter((s) => s !== curr.passes);
              curr.passes > 0 && acc.passes.push(curr.passes);
              acc.passes = acc.passes.sort((a, b) => b - a);

              acc.chances_created = acc.chances_created.filter(
                (s) => s !== curr.chances_created
              );
              curr.chances_created > 0 &&
                acc.chances_created.push(curr.chances_created);
              acc.chances_created = acc.chances_created.sort((a, b) => b - a);

              acc.accurate_passes = acc.accurate_passes.filter(
                (s) => s !== curr.accurate_passes
              );
              curr.accurate_passes > 0 &&
                acc.accurate_passes.push(curr.accurate_passes);
              acc.accurate_passes = acc.accurate_passes.sort((a, b) => b - a);

              acc.accurate_passes_percentage =
                acc.accurate_passes_percentage.filter(
                  (s) => s !== curr.accurate_passes_percentage
                );
              curr.accurate_passes_percentage > 0 &&
                acc.accurate_passes_percentage.push(
                  curr.accurate_passes_percentage
                );
              acc.accurate_passes_percentage =
                acc.accurate_passes_percentage.sort((a, b) => b - a);

              acc.passes_in_final_3rd = acc.passes_in_final_3rd.filter(
                (s) => s !== curr.passes_in_final_3rd
              );
              curr.passes_in_final_3rd > 0 &&
                acc.passes_in_final_3rd.push(curr.passes_in_final_3rd);
              acc.passes_in_final_3rd = acc.passes_in_final_3rd.sort(
                (a, b) => b - a
              );

              acc.accurate_passes_in_final_3rd_percentage =
                acc.accurate_passes_in_final_3rd_percentage.filter(
                  (s) => s !== curr.accurate_passes_in_final_3rd_percentage
                );
              curr.accurate_passes_in_final_3rd_percentage > 0 &&
                acc.accurate_passes_in_final_3rd_percentage.push(
                  curr.accurate_passes_in_final_3rd_percentage
                );
              acc.accurate_passes_in_final_3rd_percentage =
                acc.accurate_passes_in_final_3rd_percentage.sort(
                  (a, b) => b - a
                );

              acc.crosses = acc.crosses.filter((s) => s !== curr.crosses);
              curr.crosses > 0 && acc.crosses.push(curr.crosses);
              acc.crosses = acc.crosses.sort((a, b) => b - a);

              return acc;
            },
            {
              assists: [],
              touches: [],
              passes: [],
              chances_created: [],
              accurate_passes: [],
              accurate_passes_percentage: [],
              passes_in_final_3rd: [],
              accurate_passes_in_final_3rd_percentage: [],
              crosses: [],
            }
          )}
        />
      );
    });
}

function Row({ player, totals }) {
  return (
    <tr className="even:bg-gray-50 dark:even:bg-gray-800">
      <td className="p-1">
        {player?.squad?.shirt_number ?? player?.squad?.opta_shirt_number}
      </td>
      <td>
        <span className="text-gray-500">
          {player?.squad?.player?.first_name ??
            player?.squad?.player?.opta_first_name}
        </span>{" "}
        {player?.squad?.player?.last_name ??
          player?.squad?.player?.opta_last_name}
      </td>
      <td
        className={classNames(
          "text-center",
          totals?.assists?.findIndex((s) => s === player?.assists) === 0 &&
            "bg-green-400 dark:text-gray-900",
          totals?.assists?.findIndex((s) => s === player?.assists) === 1 &&
            "bg-green-300 dark:text-gray-900",
          totals?.assists?.findIndex((s) => s === player?.assists) === 2 &&
            "bg-green-200 dark:text-gray-900"
        )}
      >
        {player?.assists > 0 && player?.assists}
      </td>
      <td
        className={classNames(
          "text-center",
          totals?.chances_created?.findIndex(
            (s) => s === player?.chances_created
          ) === 0 && "bg-green-400 dark:text-gray-900",
          totals?.chances_created?.findIndex(
            (s) => s === player?.chances_created
          ) === 1 && "bg-green-300 dark:text-gray-900",
          totals?.chances_created?.findIndex(
            (s) => s === player?.chances_created
          ) === 2 && "bg-green-200 dark:text-gray-900"
        )}
      >
        {player?.chances_created > 0 && player?.chances_created}
      </td>
      <td
        className={classNames(
          "text-center",
          totals?.touches?.findIndex((s) => s === player?.touches) === 0 &&
            "bg-green-400 dark:text-gray-900",
          totals?.touches?.findIndex((s) => s === player?.touches) === 1 &&
            "bg-green-300 dark:text-gray-900",
          totals?.touches?.findIndex((s) => s === player?.touches) === 2 &&
            "bg-green-200 dark:text-gray-900"
        )}
      >
        {player?.touches > 0 && player?.touches}
      </td>
      <td
        className={classNames(
          "text-center",
          totals?.passes?.findIndex((s) => s === player?.passes) === 0 &&
            "bg-green-400 dark:text-gray-900",
          totals?.passes?.findIndex((s) => s === player?.passes) === 1 &&
            "bg-green-300 dark:text-gray-900",
          totals?.passes?.findIndex((s) => s === player?.passes) === 2 &&
            "bg-green-200 dark:text-gray-900"
        )}
      >
        {player?.passes > 0 && player?.passes}
      </td>
      <td
        className={classNames(
          "text-center",
          totals?.accurate_passes?.findIndex(
            (s) => s === player?.accurate_passes
          ) === 0 && "bg-green-400 dark:text-gray-900",
          totals?.accurate_passes?.findIndex(
            (s) => s === player?.accurate_passes
          ) === 1 && "bg-green-300 dark:text-gray-900",
          totals?.accurate_passes?.findIndex(
            (s) => s === player?.accurate_passes
          ) === 2 && "bg-green-200 dark:text-gray-900"
        )}
      >
        {player?.accurate_passes > 0 && player?.accurate_passes}
      </td>
      <td
        className={classNames(
          "text-center",
          totals?.accurate_passes_percentage?.findIndex(
            (s) => s === player?.accurate_passes_percentage
          ) === 0 && "bg-green-400 dark:text-gray-900",
          totals?.accurate_passes_percentage?.findIndex(
            (s) => s === player?.accurate_passes_percentage
          ) === 1 && "bg-green-300 dark:text-gray-900",
          totals?.accurate_passes_percentage?.findIndex(
            (s) => s === player?.accurate_passes_percentage
          ) === 2 && "bg-green-200 dark:text-gray-900"
        )}
      >
        {player?.accurate_passes_percentage > 0 &&
          player?.accurate_passes_percentage}
      </td>
      <td
        className={classNames(
          "text-center",
          totals?.passes_in_final_3rd?.findIndex(
            (s) => s === player?.passes_in_final_3rd
          ) === 0 && "bg-green-400 dark:text-gray-900",
          totals?.passes_in_final_3rd?.findIndex(
            (s) => s === player?.passes_in_final_3rd
          ) === 1 && "bg-green-300 dark:text-gray-900",
          totals?.passes_in_final_3rd?.findIndex(
            (s) => s === player?.passes_in_final_3rd
          ) === 2 && "bg-green-200 dark:text-gray-900"
        )}
      >
        {player?.passes_in_final_3rd > 0 && player?.passes_in_final_3rd}
      </td>
      <td
        className={classNames(
          "text-center",
          totals?.accurate_passes_in_final_3rd_percentage?.findIndex(
            (s) => s === player?.accurate_passes_in_final_3rd_percentage
          ) === 0 && "bg-green-400 dark:text-gray-900",
          totals?.accurate_passes_in_final_3rd_percentage?.findIndex(
            (s) => s === player?.passes_in_final_3rd
          ) === 1 && "bg-green-300 dark:text-gray-900",
          totals?.accurate_passes_in_final_3rd_percentage?.findIndex(
            (s) => s === player?.accurate_passes_in_final_3rd_percentage
          ) === 2 && "bg-green-200 dark:text-gray-900"
        )}
      >
        {player?.accurate_passes_in_final_3rd_percentage > 0 &&
          player?.accurate_passes_in_final_3rd_percentage}
      </td>
      <td
        className={classNames(
          "text-center",
          totals?.crosses?.findIndex((s) => s === player?.crosses) === 0 &&
            "bg-green-400 dark:text-gray-900",
          totals?.crosses?.findIndex((s) => s === player?.crosses) === 1 &&
            "bg-green-300 dark:text-gray-900",
          totals?.crosses?.findIndex((s) => s === player?.crosses) === 2 &&
            "bg-green-200 dark:text-gray-900"
        )}
      >
        {player?.crosses > 0 && player?.crosses}
      </td>
    </tr>
  );
}
function Totals({ team, lineup, processedTotalEvent }) {
  let totals = lineup
    ?.map((player) => {
      let assists = processedTotalEvent?.assist_events?.filter(
        (ev) => ev.playerId === player?.squad?.player?.opta_ID
      )?.length;

      let touches = processedTotalEvent?.touch_events?.filter(
        (ev) => ev.playerId === player?.squad?.player?.opta_ID
      )?.length;

      let passes = processedTotalEvent?.pass_events?.filter(
        (ev) => ev.playerId === player?.squad?.player?.opta_ID
      )?.length;

      let accurate_passes = processedTotalEvent?.pass_events?.filter(
        (ev) =>
          ev.playerId === player?.squad?.player?.opta_ID && ev.outcome === 1
      )?.length;

      let accurate_passes_percentage = (
        (accurate_passes / passes) *
        100
      ).toFixed(1);

      let passes_in_final_3rd =
        processedTotalEvent?.passes_in_final_3rd_events?.filter(
          (ev) => ev.playerId === player?.squad?.player?.opta_ID
        )?.length;

      let passes_in_final_3rd_success =
        processedTotalEvent?.passes_in_final_3rd_events?.filter(
          (ev) =>
            ev.playerId === player?.squad?.player?.opta_ID && ev.outcome === 1
        )?.length;

      let crosses = processedTotalEvent?.cross_events?.filter(
        (ev) => ev.playerId === player?.squad?.player?.opta_ID
      )?.length;

      return {
        ...player,
        assists,
        touches,
        passes,
        accurate_passes,
        accurate_passes_percentage,
        passes_in_final_3rd,
        passes_in_final_3rd_success,
        crosses,
      };
    })
    ?.reduce(
      (acc, curr) => {
        acc.assists += curr.assists;
        acc.touches += curr.touches;
        acc.passes += curr.passes;
        acc.accurate_passes += curr.accurate_passes;
        acc.passes_in_final_3rd += curr.passes_in_final_3rd;
        acc.passes_in_final_3rd_success += curr.passes_in_final_3rd_success;
        acc.crosses += curr.crosses;
        return acc;
      },
      {
        assists: 0,
        touches: 0,
        passes: 0,
        accurate_passes: 0,
        passes_in_final_3rd: 0,
        passes_in_final_3rd_success: 0,
        crosses: 0,
      }
    );

  return (
    <tr className="text-sm p-1 border-t border-b font-bold">
      <td className="p-1" colSpan={2}>
        Team totals:
      </td>

      <td className="text-center">{totals?.assists > 0 && totals?.assists}</td>
      <td className="text-center"></td>
      <td className="text-center">{totals?.touches > 0 && totals?.touches}</td>
      <td className="text-center">{totals?.passes > 0 && totals?.passes}</td>
      <td className="text-center">
        {totals?.accurate_passes > 0 && totals?.accurate_passes}
      </td>
      <td className="text-center">
        {((totals?.accurate_passes / totals?.passes) * 100).toFixed(1)}
      </td>
      <td className="text-center">
        {totals?.passes_in_final_3rd > 0 && totals?.passes_in_final_3rd}
      </td>
      <td className="text-center">
        {(
          (totals?.passes_in_final_3rd_success / totals?.passes_in_final_3rd) *
          100
        ).toFixed(1)}
      </td>
      <td className="text-center">{totals?.crosses > 0 && totals?.crosses}</td>
    </tr>
  );
}
export default PlayerDistribution;
