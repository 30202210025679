import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function LeftBar() {
  const history = useHistory();
  return (
    <div className="h-screen w-[50px] flex flex-col items-center pt-2">
      <span
        className="text-gray-400 cursor-pointer"
        onClick={() => {
          history.push({
            pathname: "/",
          });
        }}
      >
        <CalendarDaysIcon className="h-6 w-6" aria-hidden="true" />
      </span>
    </div>
  );
}

export default LeftBar;
