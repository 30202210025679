import React, { useContext, useState } from "react";
import { classNames } from "../../Utils/Utils";
import Tooltip from "../UI/Tooltip/Tooltip";
import { GameEventsContext } from "../../contexts/GameEventContext";

function PlayerAttacking({ game, home_lineup, away_lineup }) {
  const { processedTotalEvent } = useContext(GameEventsContext);
  const [selectedTeam, setSelectedTeam] = useState(game?.home_team?._id);
  return (
    <div className="flex flex-col w-full mt-4">
      <div className="flex w-full">
        <div
          onClick={() => {
            setSelectedTeam(game?.home_team?._id);
          }}
          className={classNames(
            "cursor-pointer",
            "w-1/2",
            selectedTeam === game?.home_team?._id
              ? "border-green-500 text-green-600"
              : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
            "whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
          )}
        >
          {game?.home_team?.name}
        </div>

        <div
          onClick={() => {
            setSelectedTeam(game?.away_team?._id);
          }}
          className={classNames(
            "cursor-pointer",
            "w-1/2",
            selectedTeam === game?.away_team?._id
              ? "border-green-500 text-green-600"
              : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
            "whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
          )}
        >
          {game?.away_team?.name}
        </div>
      </div>
      <div className="flex">
        <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-950">
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th className="text-sm p-1 border-t border-b text-center min-w-10">
                <Tooltip tip="Goals" className="flex w-full justify-center">
                  G
                </Tooltip>
              </th>
              <th className="text-sm p-1 border-t border-b text-center min-w-10">
                <Tooltip
                  tip="Expected goals"
                  className="flex w-full justify-center"
                >
                  xG
                </Tooltip>
              </th>
              <th className="text-sm p-1 border-t border-b text-center min-w-10">
                <Tooltip tip="Shots" className="flex w-full justify-center">
                  S
                </Tooltip>
              </th>

              <th className="text-sm p-1 border-t border-b text-center min-w-10">
                <Tooltip
                  tip="Shots on target"
                  className="flex w-full justify-center"
                >
                  SoT
                </Tooltip>
              </th>
              <th className="text-sm p-1 border-t border-b text-center min-w-10">
                <Tooltip
                  tip="Shots inside the box"
                  className="flex w-full justify-center"
                >
                  SitB
                </Tooltip>
              </th>
              <th className="text-sm p-1 border-t border-b text-center min-w-10">
                <Tooltip
                  tip="Touches in opposition box"
                  className="flex w-full justify-center"
                >
                  TiOB
                </Tooltip>
              </th>
              <th className="text-sm p-1 border-t border-b text-center min-w-10">
                <Tooltip tip="Offsides" className="flex w-full justify-center">
                  O
                </Tooltip>
              </th>
              <th className="text-sm p-1 border-t border-b text-center min-w-10">
                <Tooltip
                  tip="Total big chances"
                  className="flex w-full justify-center"
                >
                  BC
                </Tooltip>
              </th>
              <th className="text-sm p-1 border-t border-b text-center min-w-10">
                <Tooltip
                  tip="Final 3rd entries"
                  className="flex w-full justify-center"
                >
                  FTE
                </Tooltip>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white dark:bg-gray-900">
            <TableBody
              game={game}
              processedTotalEvent={processedTotalEvent}
              lineup={
                selectedTeam === game?.home_team?._id
                  ? home_lineup
                  : away_lineup
              }
              team={
                selectedTeam === game?.home_team?._id
                  ? game?.home_team
                  : game?.away_team
              }
            />
            <Totals
              team={
                selectedTeam === game?.home_team?._id
                  ? game?.home_team
                  : game?.away_team
              }
              lineup={
                selectedTeam === game?.home_team?._id
                  ? home_lineup
                  : away_lineup
              }
              processedTotalEvent={processedTotalEvent}
            />
          </tbody>
        </table>
      </div>
    </div>
  );
}

function TableBody({ team, lineup, processedTotalEvent, game }) {
  return lineup
    ?.map((player) => {
      let goals = processedTotalEvent?.[team?.opta_ID]?.goals_events?.filter(
        (ev) =>
          ev.typeId === 16 && ev.playerId === player?.squad?.player?.opta_ID
      )?.length;
      let shots = processedTotalEvent?.shot_events?.filter(
        (ev) => ev.playerId === player?.squad?.player?.opta_ID
      )?.length;
      let big_chance = processedTotalEvent?.shot_events?.filter(
        (ev) =>
          ev.playerId === player?.squad?.player?.opta_ID &&
          ev.qualifier.findIndex((q) => q.qualifierId === 214) > -1
      )?.length;

      let shots_on_target = processedTotalEvent?.shot_on_target_events?.filter(
        (ev) => ev.playerId === player?.squad?.player?.opta_ID
      )?.length;
      let shots_inside_box = processedTotalEvent?.shots_inside_box?.filter(
        (ev) => ev.playerId === player?.squad?.player?.opta_ID
      )?.length;
      let touches_in_opp_box =
        processedTotalEvent?.touches_in_opp_box_events?.filter(
          (ev) => ev.playerId === player?.squad?.player?.opta_ID
        )?.length;
      let offsides = processedTotalEvent?.offside_events?.filter(
        (ev) => ev.playerId === player?.squad?.player?.opta_ID
      )?.length;

      return {
        ...player,
        goals,
        shots,
        shots_on_target,
        shots_inside_box,
        touches_in_opp_box,
        offsides,
        big_chance,
      };
    })
    ?.map((player, _, array) => {
      return (
        <Row
          player={player}
          team={game?.home_team}
          totals={array?.reduce(
            (acc, curr) => {
              acc.goals = acc.goals.filter((s) => s !== curr.goals);
              curr.goals > 0 && acc.goals.push(curr.goals);
              acc.goals = acc.goals.sort((a, b) => b - a);
              acc.shots = acc.shots.filter((s) => s !== curr.shots);
              curr.shots > 0 && acc.shots.push(curr.shots);
              acc.shots = acc.shots.sort((a, b) => b - a);
              acc.shots_on_target = acc.shots_on_target.filter(
                (s) => s !== curr.shots_on_target
              );
              curr.shots_on_target > 0 &&
                acc.shots_on_target.push(curr.shots_on_target);
              acc.shots_on_target = acc.shots_on_target.sort((a, b) => b - a);
              acc.shots_inside_box = acc.shots_inside_box.filter(
                (s) => s !== curr.shots_inside_box
              );
              curr.shots_inside_box > 0 &&
                acc.shots_inside_box.push(curr.shots_inside_box);
              acc.shots_inside_box = acc.shots_inside_box.sort((a, b) => b - a);
              acc.touches_in_opp_box = acc.touches_in_opp_box.filter(
                (s) => s !== curr.touches_in_opp_box
              );
              curr.touches_in_opp_box > 0 &&
                acc.touches_in_opp_box.push(curr.touches_in_opp_box);
              acc.touches_in_opp_box = acc.touches_in_opp_box.sort(
                (a, b) => b - a
              );
              acc.offsides = acc.offsides.filter((s) => s !== curr.offsides);
              curr.offsides > 0 && acc.offsides.push(curr.offsides);
              acc.offsides = acc.offsides.sort((a, b) => b - a);

              acc.big_chance = acc.big_chance.filter(
                (s) => s !== curr.big_chance
              );
              curr.big_chance > 0 && acc.big_chance.push(curr.big_chance);
              acc.big_chance = acc.big_chance.sort((a, b) => b - a);
              return acc;
            },
            {
              goals: [],
              shots: [],
              shots_on_target: [],
              shots_inside_box: [],
              touches_in_opp_box: [],
              offsides: [],
              big_chance: [],
            }
          )}
        />
      );
    });
}

function Row({ player, totals }) {
  return (
    <tr className="even:bg-gray-50 dark:even:bg-gray-800">
      <td className="p-1">
        {player?.squad?.shirt_number ?? player?.squad?.opta_shirt_number}
      </td>
      <td>
        <span className="text-gray-500">
          {player?.squad?.player?.first_name ??
            player?.squad?.player?.opta_first_name}
        </span>{" "}
        {player?.squad?.player?.last_name ??
          player?.squad?.player?.opta_last_name}
      </td>
      <td
        className={classNames(
          "text-center",
          totals?.goals?.findIndex((s) => s === player?.goals) === 0 &&
            "bg-green-400 dark:text-gray-900",
          totals?.goals?.findIndex((s) => s === player?.goals) === 1 &&
            "bg-green-300 dark:text-gray-900",
          totals?.goals?.findIndex((s) => s === player?.goals) === 2 &&
            "bg-green-200 dark:text-gray-900"
        )}
      >
        {player?.goals > 0 && player?.goals}
      </td>
      <td className="text-center"></td>
      <td
        className={classNames(
          "text-center",

          totals?.goals?.findIndex((s) => s === player?.goals) === 0 &&
            "bg-green-400 dark:text-gray-900",
          totals?.goals?.findIndex((s) => s === player?.goals) === 1 &&
            "bg-green-300 dark:text-gray-900",
          totals?.goals?.findIndex((s) => s === player?.goals) === 2 &&
            "bg-green-200 dark:text-gray-900"
        )}
      >
        {player?.shots > 0 && player?.shots}
      </td>
      <td
        className={classNames(
          "text-center",
          totals?.shots_on_target?.findIndex(
            (s) => s === player?.shots_on_target
          ) === 0 && "bg-green-400 dark:text-gray-900",
          totals?.shots_on_target?.findIndex(
            (s) => s === player?.shots_on_target
          ) === 1 && "bg-green-300 dark:text-gray-900",
          totals?.shots_on_target?.findIndex(
            (s) => s === player?.shots_on_target
          ) === 2 && "bg-green-200 dark:text-gray-900"
        )}
      >
        {player?.shots_on_target > 0 && player?.shots_on_target}
      </td>
      <td
        className={classNames(
          "text-center",
          totals?.shots_inside_box?.findIndex(
            (s) => s === player?.shots_inside_box
          ) === 0 && "bg-green-400 dark:text-gray-900",
          totals?.shots_inside_box?.findIndex(
            (s) => s === player?.shots_inside_box
          ) === 1 && "bg-green-300 dark:text-gray-900",
          totals?.shots_inside_box?.findIndex(
            (s) => s === player?.shots_inside_box
          ) === 2 && "bg-green-200 dark:text-gray-900"
        )}
      >
        {player?.shots_inside_box > 0 && player?.shots_inside_box}
      </td>
      <td
        className={classNames(
          "text-center",
          totals?.touches_in_opp_box?.findIndex(
            (s) => s === player?.touches_in_opp_box
          ) === 0 && "bg-green-400 dark:text-gray-900",
          totals?.touches_in_opp_box?.findIndex(
            (s) => s === player?.touches_in_opp_box
          ) === 1 && "bg-green-300 dark:text-gray-900",
          totals?.touches_in_opp_box?.findIndex(
            (s) => s === player?.touches_in_opp_box
          ) === 2 && "bg-green-200 dark:text-gray-900"
        )}
      >
        {player?.touches_in_opp_box > 0 && player?.touches_in_opp_box}
      </td>
      <td
        className={classNames(
          "text-center",
          totals?.offsides?.findIndex((s) => s === player?.offsides) === 0 &&
            "bg-green-400 dark:text-gray-900",
          totals?.offsides?.findIndex((s) => s === player?.offsides) === 1 &&
            "bg-green-300 dark:text-gray-900",
          totals?.offsides?.findIndex((s) => s === player?.offsides) === 2 &&
            "bg-green-200 dark:text-gray-900"
        )}
      >
        {player?.offsides > 0 && player?.offsides}
      </td>
      <td
        className={classNames(
          "text-center",
          totals?.big_chance?.findIndex((s) => s === player?.big_chance) ===
            0 && "bg-green-400 dark:text-gray-900",
          totals?.big_chance?.findIndex((s) => s === player?.big_chance) ===
            1 && "bg-green-300 dark:text-gray-900",
          totals?.big_chance?.findIndex((s) => s === player?.big_chance) ===
            2 && "bg-green-200 dark:text-gray-900"
        )}
      >
        {player?.big_chance > 0 && player?.big_chance}
      </td>
      <td></td>
    </tr>
  );
}
function Totals({ team, lineup, processedTotalEvent }) {
  let totals = lineup
    ?.map((player) => {
      let goals = processedTotalEvent?.[team?.opta_ID]?.goals_events?.filter(
        (ev) =>
          ev.typeId === 16 && ev.playerId === player?.squad?.player?.opta_ID
      )?.length;
      let shots = processedTotalEvent?.shot_events?.filter(
        (ev) => ev.playerId === player?.squad?.player?.opta_ID
      )?.length;
      let big_chance = processedTotalEvent?.shot_events?.filter(
        (ev) =>
          ev.playerId === player?.squad?.player?.opta_ID &&
          ev.qualifier.findIndex((q) => q.qualifierId === 214) > -1
      )?.length;
      let shots_on_target = processedTotalEvent?.shot_on_target_events?.filter(
        (ev) => ev.playerId === player?.squad?.player?.opta_ID
      )?.length;
      let shots_inside_box = processedTotalEvent?.shots_inside_box?.filter(
        (ev) => ev.playerId === player?.squad?.player?.opta_ID
      )?.length;
      let touches_in_opp_box =
        processedTotalEvent?.touches_in_opp_box_events?.filter(
          (ev) => ev.playerId === player?.squad?.player?.opta_ID
        )?.length;
      let offsides = processedTotalEvent?.offside_events?.filter(
        (ev) => ev.playerId === player?.squad?.player?.opta_ID
      )?.length;
      return {
        ...player,
        goals,
        shots,
        shots_on_target,
        shots_inside_box,
        touches_in_opp_box,
        offsides,
        big_chance,
      };
    })
    ?.reduce(
      (acc, curr) => {
        acc.goals += curr.goals;
        acc.shots += curr.shots;
        acc.shots_on_target += curr.shots_on_target;
        acc.shots_inside_box += curr.shots_inside_box;
        acc.touches_in_opp_box += curr.touches_in_opp_box;
        acc.offsides += curr.offsides;
        acc.big_chance += curr.big_chance;
        return acc;
      },
      {
        goals: 0,
        shots: 0,
        shots_on_target: 0,
        shots_inside_box: 0,
        touches_in_opp_box: 0,
        offsides: 0,
        big_chance: 0,
      }
    );

  return (
    <tr className="text-sm p-1 border-t border-b font-bold">
      <td className="p-1" colSpan={2}>
        Team totals:
      </td>

      <td className="text-center">{totals?.goals > 0 && totals?.goals}</td>
      <td className="text-center"></td>
      <td className="text-center">{totals?.shots > 0 && totals?.shots}</td>
      <td className="text-center">
        {totals?.shots_on_target > 0 && totals?.shots_on_target}
      </td>
      <td className="text-center">
        {totals?.shots_inside_box > 0 && totals?.shots_inside_box}
      </td>
      <td className="text-center">
        {totals?.touches_in_opp_box > 0 && totals?.touches_in_opp_box}
      </td>
      <td className="text-center">
        {totals?.offsides > 0 && totals?.offsides}
      </td>
      <td className="text-center">
        {totals?.big_chance > 0 && totals?.big_chance}
      </td>
      <td className="text-center"></td>
    </tr>
  );
}
export default PlayerAttacking;
