import React, { useContext, useEffect } from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import Hyper from "./Theme/Hyper";
import Main from "./components/Views/Main";
import { UIContext, UIProvider } from "./contexts/UIContext";
import Config from "./components/Views/Config";
import { APIProvider } from "./contexts/APIContext";
import Test from "./components/Views/Test";
import { GameEventsProvider } from "./contexts/GameEventContext";

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <GameEventsProvider>
        <APIProvider>
          <UIProvider>
            <ShowMain>
              <Switch>
                <Route path="/">
                  <Main />
                </Route>
              </Switch>
            </ShowMain>
          </UIProvider>
        </APIProvider>
      </GameEventsProvider>
    </Router>
  );
}
function ShowMain({ children }) {
  const { uiView, setUIView } = useContext(UIContext);

  return <div class="container max-w-screen-xl">{children}</div>;
}
export default App;
