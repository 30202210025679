import React, { useContext } from "react";
import { GameEventsContext } from "../../contexts/GameEventContext";
import Tooltip from "../UI/Tooltip/Tooltip";

function TeamStats({ game }) {
  const {
    processedFirstEvent,
    processedSecondEvent,
    processedAETFirstEvent,
    processedAETSecondEvent,
    processedTotalEvent,
  } = useContext(GameEventsContext);
  return (
    <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-950">
      <tbody className="bg-white dark:bg-gray-900">
        <tr>
          <td
            colSpan={3}
            className="text-sm p-2 border-t dark:border-t-gray-950 border-b dark:border-b-gray-950 text-center"
          >
            {game?.home_team?.name}
          </td>
          <td className="text-sm p-2 border-t dark:border-t-gray-950 border-b dark:border-b-gray-950 text-center"></td>
          <td
            colSpan={3}
            className="text-sm p-1 border-t border-b dark:border-t-gray-950 dark:border-b-gray-950 text-center"
          >
            {game?.away_team?.name}
          </td>
        </tr>
        <tr>
          <td
            colSpan={1}
            className="text-sm p-1 border-t border-b dark:border-b-gray-950 text-center"
          >
            <Tooltip tip="First half" className="flex w-full justify-center">
              FH
            </Tooltip>
          </td>
          <td className="text-sm p-1 border-t border-b dark:border-b-gray-950 text-center">
            <Tooltip tip="Second half" className="flex w-full justify-center">
              SH
            </Tooltip>
          </td>
          <td className="text-sm p-1 border-t border-b dark:border-b-gray-950 text-center">
            <Tooltip tip="Total" className="flex w-full justify-center">
              T
            </Tooltip>
          </td>
          <td
            colSpan={1}
            className="text-sm p-1 border-t border-b dark:border-b-gray-950"
          ></td>
          <td className="text-sm p-1 border-t dark:border-t-gray-950 border-b dark:border-b-gray-950 text-center">
            <Tooltip tip="Total" className="flex w-full justify-center">
              T
            </Tooltip>
          </td>
          <td
            colSpan={1}
            className="text-sm p-1 border-t dark:border-t-gray-950 border-b dark:border-b-gray-950  text-center"
          >
            <Tooltip tip="First half" className="flex w-full justify-center">
              FH
            </Tooltip>
          </td>
          <td className="text-sm p-1 border-t dark:border-t-gray-950 border-b dark:border-b-gray-950 text-center">
            <Tooltip tip="Second half" className="flex w-full justify-center">
              SH
            </Tooltip>
          </td>
        </tr>

        <Stat
          game={game}
          events={processedTotalEvent}
          first_half_events={processedFirstEvent}
          second_half_events={processedSecondEvent}
          category={"shots_total"}
          title={"Shots"}
        />
        <Stat
          game={game}
          events={processedTotalEvent}
          first_half_events={processedFirstEvent}
          second_half_events={processedSecondEvent}
          category={"shots_on_target"}
          title={"On target"}
        />
        <Stat
          game={game}
          events={processedTotalEvent}
          first_half_events={processedFirstEvent}
          second_half_events={processedSecondEvent}
          category={"shots_inside_box"}
          title={"Shots inside box"}
        />
        <Stat
          game={game}
          events={processedTotalEvent}
          first_half_events={processedFirstEvent}
          second_half_events={processedSecondEvent}
          category={"woodwork_total"}
          title={"Hit woodwork"}
        />
        <Stat
          game={game}
          events={processedTotalEvent}
          first_half_events={processedFirstEvent}
          second_half_events={processedSecondEvent}
          category={"offside_total"}
          title={"Offsides"}
        />
        <Stat
          game={game}
          events={processedTotalEvent}
          first_half_events={processedFirstEvent}
          second_half_events={processedSecondEvent}
          category={"touches_in_opp_box"}
          title={"Touches in opp. box"}
        />

        <Stat
          game={game}
          events={processedTotalEvent}
          first_half_events={processedFirstEvent}
          second_half_events={processedSecondEvent}
          category={"possession"}
          title={"Possession"}
        />
        <Stat
          game={game}
          events={processedTotalEvent}
          first_half_events={processedFirstEvent}
          second_half_events={processedSecondEvent}
          category={"pass_total"}
          title={"Passes"}
        />
        <Stat
          game={game}
          events={processedTotalEvent}
          first_half_events={processedFirstEvent}
          second_half_events={processedSecondEvent}
          title={"Successful passes"}
          category={"pass_success"}
        />
        <Stat
          game={game}
          events={processedTotalEvent}
          first_half_events={processedFirstEvent}
          second_half_events={processedSecondEvent}
          title={"Passing accuracy"}
          category={"pass_completion"}
        />
        <Stat
          game={game}
          events={processedTotalEvent}
          first_half_events={processedFirstEvent}
          second_half_events={processedSecondEvent}
          title={"Passes in final 3rd"}
          category={"pass_in_final_3rd"}
        />
        <Stat
          game={game}
          events={processedTotalEvent}
          first_half_events={processedFirstEvent}
          second_half_events={processedSecondEvent}
          title={"Successful passes in final 3rd"}
          category={"pass_in_final_3rd_success"}
        />
        <Stat
          game={game}
          events={processedTotalEvent}
          first_half_events={processedFirstEvent}
          second_half_events={processedSecondEvent}
          category={"fieldTilt"}
          title={"Field tilt"}
        />
        <Stat
          game={game}
          events={processedTotalEvent}
          first_half_events={processedFirstEvent}
          second_half_events={processedSecondEvent}
          title={"% of passes in final 3rd"}
          category={"passesInFinalThird"}
        />
        <Stat
          game={game}
          events={processedTotalEvent}
          first_half_events={processedFirstEvent}
          second_half_events={processedSecondEvent}
          title={"Passes into final 3rd"}
          category={"pass_into_final_3rd"}
        />
        <Stat
          game={game}
          events={processedTotalEvent}
          first_half_events={processedFirstEvent}
          second_half_events={processedSecondEvent}
          title={"Corners"}
          category={"corners_total"}
        />
        <Stat
          game={game}
          events={processedTotalEvent}
          first_half_events={processedFirstEvent}
          second_half_events={processedSecondEvent}
          title={"Crosses"}
          category={"crosses_total"}
        />
        <Stat
          game={game}
          events={processedTotalEvent}
          first_half_events={processedFirstEvent}
          second_half_events={processedSecondEvent}
          title={"Fouls conceded"}
          category={"fouls_conceded"}
        />
        <Stat
          game={game}
          events={processedTotalEvent}
          first_half_events={processedFirstEvent}
          second_half_events={processedSecondEvent}
          title={"Yellow cards"}
          category={"yellow_card_total"}
        />
        <Stat
          game={game}
          events={processedTotalEvent}
          first_half_events={processedFirstEvent}
          second_half_events={processedSecondEvent}
          title={"Red cards"}
          category={"red_card_total"}
        />
        <Stat
          game={game}
          events={processedTotalEvent}
          first_half_events={processedFirstEvent}
          second_half_events={processedSecondEvent}
          title={"Defensive actions"}
          category={"defensive_actions"}
        />
        <Stat
          game={game}
          events={processedTotalEvent}
          first_half_events={processedFirstEvent}
          second_half_events={processedSecondEvent}
          title={"Tackles"}
          category={"tackles_total"}
        />
        <Stat
          game={game}
          events={processedTotalEvent}
          first_half_events={processedFirstEvent}
          second_half_events={processedSecondEvent}
          title={"Interceptions"}
          category={"interception_total"}
        />
        <Stat
          game={game}
          events={processedTotalEvent}
          first_half_events={processedFirstEvent}
          second_half_events={processedSecondEvent}
          title={"Clearances"}
          category={"clearance_total"}
        />
        <Stat
          game={game}
          events={processedTotalEvent}
          first_half_events={processedFirstEvent}
          second_half_events={processedSecondEvent}
          title={"Aerial duels won"}
          category={"aerials_won"}
        />
        <Stat
          game={game}
          events={processedTotalEvent}
          first_half_events={processedFirstEvent}
          second_half_events={processedSecondEvent}
          title={"Possession won in final 3rd"}
          category={"poss_won_in_final_3rd"}
        />
      </tbody>
    </table>
  );
}

function Stat({
  events,
  first_half_events,
  second_half_events,
  game,
  category,
  title,
}) {
  if (category === "possession") {
    if (first_half_events?.[game?.home_team?.opta_ID]) {
      first_half_events[game?.home_team?.opta_ID][category] = parseInt(
        ((first_half_events?.[game?.home_team?.opta_ID]?.pass_total || 0) /
          ((first_half_events?.[game?.home_team?.opta_ID]?.pass_total || 0) +
            (first_half_events?.[game?.away_team?.opta_ID]?.pass_total || 0)) ||
          0) * 100
      );
      first_half_events[game?.away_team?.opta_ID][category] =
        100 - first_half_events[game?.home_team?.opta_ID][category];
    }
    if (second_half_events?.[game?.home_team?.opta_ID]) {
      second_half_events[game?.home_team?.opta_ID][category] = parseInt(
        ((second_half_events?.[game?.home_team?.opta_ID]?.pass_total || 0) /
          ((second_half_events?.[game?.home_team?.opta_ID]?.pass_total || 0) +
            (second_half_events?.[game?.away_team?.opta_ID]?.pass_total ||
              0)) || 0) * 100
      );
      second_half_events[game?.away_team?.opta_ID][category] =
        100 - second_half_events[game?.home_team?.opta_ID][category];
    }
    if (events?.[game?.home_team?.opta_ID]) {
      events[game?.home_team?.opta_ID][category] = parseInt(
        ((events?.[game?.home_team?.opta_ID]?.pass_total || 0) /
          ((events?.[game?.home_team?.opta_ID]?.pass_total || 0) +
            (events?.[game?.away_team?.opta_ID]?.pass_total || 0)) || 0) * 100
      );
      events[game?.away_team?.opta_ID][category] =
        100 - events[game?.home_team?.opta_ID][category];
    }
  }
  if (category === "fieldTilt") {
    if (first_half_events?.[game?.home_team?.opta_ID]) {
      first_half_events[game?.home_team?.opta_ID][category] = parseInt(
        ((first_half_events?.[game?.home_team?.opta_ID]?.pass_in_final_3rd ||
          0) /
          ((first_half_events?.[game?.home_team?.opta_ID]?.pass_in_final_3rd ||
            0) +
            (first_half_events?.[game?.away_team?.opta_ID]?.pass_in_final_3rd ||
              0)) || 0) * 100
      );
      first_half_events[game?.away_team?.opta_ID][category] =
        100 - first_half_events[game?.home_team?.opta_ID][category];
    }
    if (second_half_events?.[game?.home_team?.opta_ID]) {
      second_half_events[game?.home_team?.opta_ID][category] = parseInt(
        ((second_half_events?.[game?.home_team?.opta_ID]?.pass_in_final_3rd ||
          0) /
          ((second_half_events?.[game?.home_team?.opta_ID]?.pass_in_final_3rd ||
            0) +
            (second_half_events?.[game?.away_team?.opta_ID]
              ?.pass_in_final_3rd || 0)) || 0) * 100
      );
      second_half_events[game?.away_team?.opta_ID][category] =
        100 - second_half_events[game?.home_team?.opta_ID][category];
    }
    if (events?.[game?.home_team?.opta_ID]) {
      events[game?.home_team?.opta_ID][category] = parseInt(
        ((events?.[game?.home_team?.opta_ID]?.pass_in_final_3rd || 0) /
          ((events?.[game?.home_team?.opta_ID]?.pass_in_final_3rd || 0) +
            (events?.[game?.away_team?.opta_ID]?.pass_in_final_3rd || 0)) ||
          0) * 100
      );
      events[game?.away_team?.opta_ID][category] =
        100 - events[game?.home_team?.opta_ID][category];
    }
  }

  if (category === "passesInFinalThird") {
    if (first_half_events?.[game?.home_team?.opta_ID]) {
      first_half_events[game?.home_team?.opta_ID][category] = parseInt(
        ((first_half_events?.[game?.home_team?.opta_ID]?.pass_in_final_3rd ||
          0) /
          (first_half_events?.[game?.home_team?.opta_ID]?.pass_total || 0) ||
          0) * 100
      );
      first_half_events[game?.away_team?.opta_ID][category] =
        100 - first_half_events[game?.home_team?.opta_ID][category];
    }

    if (second_half_events?.[game?.home_team?.opta_ID]) {
      second_half_events[game?.home_team?.opta_ID][category] = parseInt(
        ((second_half_events?.[game?.home_team?.opta_ID]?.pass_in_final_3rd ||
          0) /
          (second_half_events?.[game?.home_team?.opta_ID]?.pass_total || 0) ||
          0) * 100
      );
      second_half_events[game?.away_team?.opta_ID][category] =
        100 - second_half_events[game?.home_team?.opta_ID][category];
    }

    if (events?.[game?.home_team?.opta_ID]) {
      events[game?.home_team?.opta_ID][category] = parseInt(
        ((events?.[game?.home_team?.opta_ID]?.pass_in_final_3rd || 0) /
          (events?.[game?.home_team?.opta_ID]?.pass_total || 0) || 0) * 100
      );
      events[game?.away_team?.opta_ID][category] =
        100 - events[game?.home_team?.opta_ID][category];
    }
  }

  let home_value = events?.[game?.home_team?.opta_ID]?.[category] ?? 0;
  let away_value = events?.[game?.away_team?.opta_ID]?.[category];
  let homePercentage = (home_value / (home_value + away_value)) * 100;
  let awayPercentage = 100 - homePercentage;

  return (
    <tr className="even:bg-gray-50 dark:even:bg-gray-800">
      <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium sm:pl-3 text-center">
        {first_half_events?.[game?.home_team?.opta_ID]?.[category] || 0}
      </td>
      <td className="whitespace-nowrap py-1 pl-4 pr-3 text-sm font-medium  sm:pl-3 text-center">
        {second_half_events?.[game?.home_team?.opta_ID]?.[category] || 0}
      </td>
      <td className="whitespace-nowrap py-1 pl-4 pr-3 text-sm font-medium  sm:pl-3 text-center bg-slate-200 dark:bg-gray-950">
        {events?.[game?.home_team?.opta_ID]?.[category] || 0}
      </td>
      <td className="whitespace-nowrap py-1 pl-4 pr-3 text-sm font-medium  sm:pl-3 text-center">
        {title}
        <div className="flex">
          <div
            className="h-[3px] border"
            style={{
              backgroundColor:
                game?.home_team?.variables?.[game?.home_team_kit + "_kit_bg"],
              width: homePercentage + "%",
            }}
          ></div>
          <div
            className="h-[3px] border"
            style={{
              backgroundColor:
                game?.away_team?.variables?.[game?.away_team_kit + "_kit_bg"],
              width: awayPercentage + "%",
            }}
          ></div>
        </div>
      </td>
      <td className="whitespace-nowrap py-1 pl-4 pr-3 text-sm font-medium  sm:pl-3 text-center  bg-slate-200 dark:bg-gray-950">
        {events?.[game?.away_team?.opta_ID]?.[category] || 0}
      </td>
      <td className="whitespace-nowrap pl-4 pr-3 text-sm font-medium   sm:pl-3 text-center">
        {first_half_events?.[game?.away_team?.opta_ID]?.[category] || 0}
      </td>
      <td className="whitespace-nowrap px-3 text-sm  text-center">
        {second_half_events?.[game?.away_team?.opta_ID]?.[category] || 0}
      </td>
    </tr>
  );
}

export default TeamStats;
