import React, { useContext, useEffect, useState } from "react";
import Pitch from "../../Images/LargePitch.png";
import { GameEventsContext } from "../../contexts/GameEventContext";
import { UserContext } from "../../contexts/UserContext";
import { classNames } from "../../Utils/Utils";
function AttackingAreas({ game }) {
  const { user } = useContext(UserContext);
  const { processedTotalEvent, processedFirstEvent, processedSecondEvent } =
    useContext(GameEventsContext);
  const [half, setHalf] = useState(1);
  const [view, setView] = useState(1);
  const [stats, setStats] = useState([]);
  useEffect(() => {
    if (view === 1) {
      setStats(processedFirstEvent);
      setHalf(1);
    } else if (view === 2) {
      setStats(processedSecondEvent);
      setHalf(2);
    } else if (view === 0) {
      setStats(processedTotalEvent);
      setHalf(1);
    }
  }, [view, processedTotalEvent, processedFirstEvent, processedSecondEvent]);
  const [tabs, setTabs] = useState([
    { name: "1st half", id: "1st_half", current: true, value: 1 },
    { name: "2nd half", id: "2nd_half", current: false, value: 2 },
    { name: "Full game", id: "full_game", current: false, value: 0 },
  ]);
  return (
    <div className="justify-center flex-col w-full">
      <div className="border-b border-gray-200">
        <nav aria-label="Tabs" className="-mb-px flex space-x-8">
          {tabs.map((tab) => (
            <a
              key={tab.name}
              onClick={() => {
                let new_tabs = [...tabs];
                new_tabs = new_tabs?.map((t) => {
                  return { ...t, current: false };
                });
                new_tabs.find((t) => t.name === tab.name).current = true;
                setTabs(new_tabs);
                setView(tab.value);
              }}
              aria-current={tab.current ? "page" : undefined}
              className={classNames(
                "cursor-pointer",
                tab.current
                  ? "border-green-500 text-green-600"
                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                "whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
              )}
            >
              {tab.name}
            </a>
          ))}
        </nav>
      </div>
      <div className="flex mt-10">
        <div className="relative w-1/2 m-1">
          {game?.home_team?.name}
          <img
            src={Pitch}
            className="w-full filter invert-[40%] sepia-[14%] saturate-[391%]"
          />
          {(processedTotalEvent?.["kick_off_" + half]?.[
            game?.home_team?.opta_ID
          ] === "Left to Right" ||
            processedTotalEvent?.["kick_off_" + half]?.[
              game?.away_team?.opta_ID
            ] === "Right to Left") && (
            <div className="absolute flex top-14 left-[50%] items-center opacity-25 w-1/2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-[100%]"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                />
              </svg>
            </div>
          )}
          {(processedTotalEvent?.["kick_off_" + half]?.[
            game?.home_team?.opta_ID
          ] === "Right to Left" ||
            processedTotalEvent?.["kick_off_" + half]?.[
              game?.away_team?.opta_ID
            ] === "Left to Right") && (
            <div className="absolute flex top-14 left-[0%] items-center opacity-25 w-1/2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-[100%]"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
                />
              </svg>
            </div>
          )}
          <div
            className="absolute flex w-full h-full top-3 text-4xl font-bold"
            style={{
              flexDirection:
                processedTotalEvent?.["kick_off_" + half]?.[
                  game?.home_team?.opta_ID
                ] === "Left to Right" ||
                processedTotalEvent?.["kick_off_" + half]?.[
                  game?.away_team?.opta_ID
                ] === "Right to Left"
                  ? "column-reverse"
                  : "column",
            }}
          >
            <div className="flex h-1/3 justify-center items-center">
              {Math.round(
                (stats?.[game?.home_team?.opta_ID]?.attacking_area_right /
                  stats?.[game?.home_team?.opta_ID]?.attacking_area_total) *
                  100
              ) || 0}
              %
            </div>
            <div className="flex  h-1/3 justify-center items-center">
              {Math.round(
                (stats?.[game?.home_team?.opta_ID]?.attacking_area_centre /
                  stats?.[game?.home_team?.opta_ID]?.attacking_area_total) *
                  100
              ) || 0}
              %
            </div>
            <div className="flex h-1/3 justify-center items-center">
              {100 -
                ((Math.round(
                  (stats?.[game?.home_team?.opta_ID]?.attacking_area_right /
                    stats?.[game?.home_team?.opta_ID]?.attacking_area_total) *
                    100
                ) || 0) +
                  (Math.round(
                    (stats?.[game?.home_team?.opta_ID]?.attacking_area_centre /
                      stats?.[game?.home_team?.opta_ID]?.attacking_area_total) *
                      100
                  ) || 0))}
              %
            </div>
          </div>
        </div>
        <div className="relative w-1/2 m-1">
          {game?.away_team?.name}
          <img
            src={Pitch}
            className="w-full filter invert-[40%] sepia-[14%] saturate-[391%]"
          />
          {(processedTotalEvent?.["kick_off_" + half]?.[
            game?.home_team?.opta_ID
          ] === "Right to Left" ||
            processedTotalEvent?.["kick_off_" + half]?.[
              game?.away_team?.opta_ID
            ] === "Left to Right") && (
            <div className="absolute flex top-14 left-[50%] items-center opacity-25 w-1/2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-[100%]"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                />
              </svg>
            </div>
          )}
          {(processedTotalEvent?.["kick_off_" + half]?.[
            game?.home_team?.opta_ID
          ] === "Left to Right" ||
            processedTotalEvent?.["kick_off_" + half]?.[
              game?.away_team?.opta_ID
            ] === "Right to Left") && (
            <div className="absolute flex top-14 left-0 items-center opacity-25 w-1/2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-[100%]"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
                />
              </svg>
            </div>
          )}
          <div
            className="absolute flex w-full h-full top-3 text-4xl font-bold"
            style={{
              flexDirection:
                processedTotalEvent?.["kick_off_" + half]?.[
                  game?.home_team?.opta_ID
                ] === "Left to Right" ||
                processedTotalEvent?.["kick_off_" + half]?.[
                  game?.away_team?.opta_ID
                ] === "Right to Left"
                  ? "column"
                  : "column-reverse",
            }}
          >
            <div className="flex h-1/3 justify-center items-center">
              {Math.round(
                (stats?.[game?.away_team?.opta_ID]?.attacking_area_right /
                  stats?.[game?.away_team?.opta_ID]?.attacking_area_total) *
                  100
              ) || 0}
              %
            </div>
            <div className="flex  h-1/3 justify-center items-center">
              {Math.round(
                (stats?.[game?.away_team?.opta_ID]?.attacking_area_centre /
                  stats?.[game?.away_team?.opta_ID]?.attacking_area_total) *
                  100
              ) || 0}
              %
            </div>
            <div className="flex h-1/3 justify-center items-center">
              {100 -
                ((Math.round(
                  (stats?.[game?.away_team?.opta_ID]?.attacking_area_right /
                    stats?.[game?.away_team?.opta_ID]?.attacking_area_total) *
                    100
                ) || 0) +
                  (Math.round(
                    (stats?.[game?.away_team?.opta_ID]?.attacking_area_centre /
                      stats?.[game?.away_team?.opta_ID]?.attacking_area_total) *
                      100
                  ) || 0))}
              %
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AttackingAreas;
