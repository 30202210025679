import React, { useContext, useState } from "react";
import PlayerStats from "./PlayerStats";
import { UIContext } from "../../contexts/UIContext";
import { GameEventsContext } from "../../contexts/GameEventContext";
import Panel from "../UI/Panel/Panel";

function PlayerBar({ game }) {
  const { selectedPlayers, setSelectedPlayers } = useContext(UIContext);
  const { events } = useContext(GameEventsContext);

  return (
    <div className="flex w-screen fixed bottom-0 ml-2 pointer-events-none overflow-hidden max-h-[360px] min-h-[360px]">
      {selectedPlayers?.map((player, index) => {
        return (
          <PlayerStats
            key={player?._id}
            player={player}
            index={index}
            onClose={() => {
              let new_players = [...selectedPlayers];
              new_players = new_players.filter(
                (p) =>
                  p?.squad?.player?.opta_ID !== player.squad?.player?.opta_ID
              );

              setSelectedPlayers(new_players);
            }}
            optaEvents={events?.filter((ev) => {
              return ev.playerId === player?.opta_ID;
            })}
            game={game}
          />
        );
      })}
      {selectedPlayers.length > 1 && (
        <div className="pointer-events-auto relative min-h-[40px] max-h-[40px] transition-all -bottom-[303px]">
          <Panel className="shadow-xl drop-shadow-xl text-sm transition-all px-0 sm:px-1 py-0 sm:py-0">
            <div className="flex p-2">
              <svg
                onClick={() => {
                  setSelectedPlayers([]);
                }}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4 cursor-pointer hover:text-[#4ade80]"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </div>
          </Panel>
        </div>
      )}
    </div>
  );
}

export default PlayerBar;
