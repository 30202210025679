import React, { useContext, useState, useRef, useEffect } from "react";
import Panel from "../UI/Panel/Panel";
import EventList from "../EventList/EventList";
import EFLLineup from "../Lineup/EFLLineup";
import TeamStats from "./TeamStats";
import Formation from "./Formation";
import { GameEventsContext } from "../../contexts/GameEventContext";
import PlayerAttacking from "./PlayerAttacking";
import { classNames } from "../../Utils/Utils";
import PlayerDistribution from "./PlayerDistribution";
import PlayerGeneral from "./PlayerGeneral";
import PlayerDefence from "./PlayerDefence";
import { useReactToPrint } from "react-to-print";
import Insights from "./Insights";
import PlayerStats from "../PlayerStats/PlayerStats";
import PlayerBar from "../PlayerStats/PlayerBar";
import ActionAreas from "../ActionAreas/ActionAreas";
import AttackingAreas from "../AttackingAreas/AttackingAreas";
import ZonesOfControl from "../ZonesOfControl/ZonesOfControl";
function EFLGame({ optaEvents, game }) {
  const componentRef = useRef();
  const promiseResolveRef = useRef(null);
  const [isPrinting, setIsPrinting] = useState(false);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  // We watch for the state to change here, and for the Promise resolve to be available
  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
      promiseResolveRef.current();
    }
  }, [isPrinting]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: game?.description,
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        promiseResolveRef.current = resolve;
        setIsPrinting(true);
      });
    },
    onAfterPrint: () => {
      // Reset the Promise resolve so we can print again
      promiseResolveRef.current = null;
      setIsPrinting(false);
    },
  });

  const { events: opta_events } = useContext(GameEventsContext);
  const [tabs, setTabs] = useState([
    { name: "Pre-Game Insights", id: "pre_insights", current: false },
    { name: "Events", id: "events", current: true },
    { name: "Formation", id: "formation", current: false },
    { name: "Action Areas", id: "action_areas", current: false },
    { name: "Attacking Areas", id: "attacking_areas", current: false },
    { name: "Zones of Control", id: "zones_of_control", current: false },
  ]);
  const [gameTabs, setGameTabs] = useState([
    { name: "Lineup", id: "lineup", current: true },
    { name: "Team Stats", id: "team_stats", current: false },
    { name: "Player Attacking", id: "player_attacking", current: false },
    { name: "Player Distribution", id: "player_distribution", current: false },
    { name: "Player General", id: "player_general", current: false },
    { name: "Player Defence", id: "player_defence", current: false },
  ]);
  function processSub({ lineup, events, team }) {
    let new_lineup = JSON.parse(JSON.stringify(lineup));
    //TODO make it work if sub comes on and then gets subbed off
    let sub_events = events?.filter((ev) => ev.typeId === 18);
    for (let i = 0; i < sub_events?.length; i++) {
      let off_ev = sub_events[i];

      let on_ev = events?.find((item) => {
        return (
          item.eventId ===
            parseInt(
              off_ev?.qualifier?.find((q) => q.qualifierId === 55)?.value
            ) && item.contestantId === team?.opta_ID
        );
      });
      let off_player = new_lineup?.find(
        (player) => off_ev?.playerId === player.squad?.player?.opta_ID
      );
      let on_player = new_lineup?.find(
        (player) => on_ev?.playerId === player.squad?.player?.opta_ID
      );
      if (on_player && off_player) {
        let off_player_order = off_player?.order;
        let on_player_order = on_player?.order;

        off_player.order = on_player_order;
        on_player.order = off_player_order;
      }
    }
    return new_lineup;
  }

  return (
    <div className="flex flex-col xl:flex-row">
      <Panel className="flex flex-col w-full xl:w-1/2">
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav
              aria-label="Tabs"
              className="-mb-px flex space-x-8 overflow-x-auto"
            >
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  onClick={() => {
                    let new_tabs = [...tabs];
                    new_tabs = new_tabs?.map((t) => {
                      return { ...t, current: false };
                    });
                    new_tabs.find((t) => t.name === tab.name).current = true;
                    setTabs(new_tabs);
                  }}
                  aria-current={tab.current ? "page" : undefined}
                  className={classNames(
                    "cursor-pointer",
                    tab.current
                      ? "border-green-500 text-green-600"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
                  )}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
        </div>
        {tabs.find((t) => t?.id === "events")?.current && (
          <EventList game={game} />
        )}
        {tabs.find((t) => t?.id === "formation")?.current && (
          <Formation
            events={optaEvents}
            game={game}
            home_team={game?.home_team}
            away_team={game?.away_team}
            home_formation={game?.home_formation}
            away_formation={game?.away_formation}
            home_lineup={
              processSub({
                lineup: game?.lineup?.home,
                events: optaEvents,
                team: game?.home_team,
              }) || game.opta_lineup?.home
            }
            away_lineup={
              processSub({
                lineup: game?.lineup?.away,
                events: optaEvents,
                team: game?.away_team,
              }) || game.opta_lineup?.away
            }
            goals={
              optaEvents?.filter((item) => {
                try {
                  return (
                    (item.typeId === 16 ||
                      ((item.typeId === 13 ||
                        item.typeId === 14 ||
                        item.typeId === 15) &&
                        item.qualifier.findIndex((q) => q.qualifierId === 9) >
                          -1)) &&
                    item?.periodId !== 5
                  );
                } catch (e) {
                  return false;
                }
              }) || []
            }
            cards={
              optaEvents?.filter((item) => {
                try {
                  return item.typeId === 17;
                } catch (e) {
                  return false;
                }
              }) || []
            }
            subs={
              optaEvents
                ?.filter((item) => {
                  try {
                    return item.typeId === 18 || item.typeId === 19;
                  } catch (e) {
                    return false;
                  }
                })
                ?.map((sub) => {
                  let related_event = optaEvents?.find(
                    (e) =>
                      e.contestantId === sub.contestantId &&
                      e.eventId ===
                        parseInt(
                          sub?.qualifier?.find((q) => q?.qualifierId === 55)
                            ?.value
                        )
                  );

                  return { ...sub, related_event };
                }) || []
            }
            assists={
              optaEvents
                ?.filter((item) => {
                  try {
                    return item.typeId === 16 && item?.periodId !== 5;
                  } catch (e) {
                    return false;
                  }
                })
                ?.map((item) => {
                  let related_event = opta_events?.find(
                    (e) =>
                      e.eventId ===
                      parseInt(
                        item?.qualifier?.find((q) => q?.qualifierId === 55)
                          ?.value
                      )
                  );
                  return related_event;
                }) || []
            }
          />
        )}
        {tabs.find((t) => t?.id === "pre_insights")?.current && (
          <Insights game={game} />
        )}
        {tabs.find((t) => t?.id === "action_areas")?.current && (
          <ActionAreas game={game} />
        )}
        {tabs.find((t) => t?.id === "attacking_areas")?.current && (
          <AttackingAreas game={game} />
        )}
        {tabs.find((t) => t?.id === "zones_of_control")?.current && (
          <ZonesOfControl game={game} />
        )}
      </Panel>
      <Panel className="flex flex-col w-full xl:w-1/2">
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav
              aria-label="Tabs"
              className="-mb-px flex space-x-8 overflow-x-auto"
            >
              {gameTabs.map((tab) => (
                <div
                  key={tab.name}
                  onClick={() => {
                    let new_tabs = [...gameTabs];
                    new_tabs = new_tabs?.map((t) => {
                      return { ...t, current: false };
                    });
                    new_tabs.find((t) => t.name === tab.name).current = true;
                    setGameTabs(new_tabs);
                  }}
                  aria-current={tab.current ? "page" : undefined}
                  className={classNames(
                    "cursor-pointer",
                    tab.current
                      ? "border-green-500 text-green-600"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
                  )}
                >
                  {tab.name}
                </div>
              ))}
            </nav>
          </div>
        </div>
        {gameTabs.find((t) => t?.id === "lineup")?.current && (
          <div className="flex">
            <div className="w-1/2">
              <EFLLineup
                game={game}
                team={game.home_team}
                lineup={
                  processSub({
                    lineup: game?.lineup?.home,
                    events: optaEvents,
                    team: game?.home_team,
                  }) ??
                  game.opta_lineup?.home ??
                  []
                }
                optaEvents={optaEvents}
                allOPTAEvents={opta_events}
              />
            </div>
            <div className="w-1/2">
              <EFLLineup
                printer={true}
                game={game}
                team={game.away_team}
                lineup={
                  processSub({
                    lineup: game?.lineup?.away,
                    events: optaEvents,
                    team: game?.away_team,
                  }) ??
                  game.opta_lineup?.away ??
                  []
                }
                optaEvents={optaEvents}
                allOPTAEvents={opta_events}
                print={handlePrint}
              />
            </div>
          </div>
        )}
        {isPrinting && (
          <div className={classNames("flex", "m-10")} ref={componentRef}>
            <div className="w-1/2">
              <EFLLineup
                game={game}
                team={game.home_team}
                lineup={
                  processSub({
                    lineup: game?.lineup?.home,
                    events: optaEvents,
                    team: game?.home_team,
                  }) ??
                  game.opta_lineup?.home ??
                  []
                }
                optaEvents={optaEvents}
                allOPTAEvents={opta_events}
              />
            </div>
            <div className="w-1/2">
              <EFLLineup
                printer={false}
                game={game}
                team={game.away_team}
                lineup={
                  processSub({
                    lineup: game?.lineup?.away,
                    events: optaEvents,
                    team: game?.away_team,
                  }) ??
                  game.opta_lineup?.away ??
                  []
                }
                optaEvents={optaEvents}
                allOPTAEvents={opta_events}
              />
            </div>
          </div>
        )}
        {gameTabs.find((t) => t?.id === "team_stats")?.current && (
          <div className="flex w-full">
            <TeamStats game={game} events={optaEvents} />
          </div>
        )}
        {gameTabs.find((t) => t?.id === "player_attacking")?.current && (
          <div className="flex w-full">
            <PlayerAttacking
              game={game}
              events={optaEvents}
              home_lineup={
                processSub({
                  lineup: game?.lineup?.home,
                  events: optaEvents,
                  team: game?.home_team,
                }) ??
                game.opta_lineup?.home ??
                []
              }
              away_lineup={
                processSub({
                  lineup: game?.lineup?.away,
                  events: optaEvents,
                  team: game?.away_team,
                }) ??
                game.opta_lineup?.away ??
                []
              }
            />
          </div>
        )}
        {gameTabs.find((t) => t?.id === "player_distribution")?.current && (
          <div className="flex w-full">
            <PlayerDistribution
              game={game}
              events={optaEvents}
              home_lineup={
                processSub({
                  lineup: game?.lineup?.home,
                  events: optaEvents,
                  team: game?.home_team,
                }) ??
                game.opta_lineup?.home ??
                []
              }
              away_lineup={
                processSub({
                  lineup: game?.lineup?.away,
                  events: optaEvents,
                  team: game?.away_team,
                }) ??
                game.opta_lineup?.away ??
                []
              }
            />
          </div>
        )}
        {gameTabs.find((t) => t?.id === "player_general")?.current && (
          <div className="flex w-full">
            <PlayerGeneral
              game={game}
              events={optaEvents}
              home_lineup={
                processSub({
                  lineup: game?.lineup?.home,
                  events: optaEvents,
                  team: game?.home_team,
                }) ??
                game.opta_lineup?.home ??
                []
              }
              away_lineup={
                processSub({
                  lineup: game?.lineup?.away,
                  events: optaEvents,
                  team: game?.away_team,
                }) ??
                game.opta_lineup?.away ??
                []
              }
            />
          </div>
        )}
        {gameTabs.find((t) => t?.id === "player_defence")?.current && (
          <div className="flex w-full">
            <PlayerDefence
              game={game}
              events={optaEvents}
              home_lineup={
                processSub({
                  lineup: game?.lineup?.home,
                  events: optaEvents,
                  team: game?.home_team,
                }) ??
                game.opta_lineup?.home ??
                []
              }
              away_lineup={
                processSub({
                  lineup: game?.lineup?.away,
                  events: optaEvents,
                  team: game?.away_team,
                }) ??
                game.opta_lineup?.away ??
                []
              }
            />
          </div>
        )}
      </Panel>
      <PlayerBar game={game} optaEvents={optaEvents} />
    </div>
  );
}

export default EFLGame;
