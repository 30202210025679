import { GiSoccerBall } from "react-icons/gi";
import Tooltip from "../UI/Tooltip/Tooltip";
import {
  ArrowPathIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";

export function YellowCard({ card }) {
  return (
    <Tooltip
      tip={"Yellow card: " + card?.playerName + " " + (card?.timeMin + 1) + "'"}
    >
      <div className="w-[10px] h-[15px] bg-yellow-300 ml-1"></div>
    </Tooltip>
  );
}
export function DoubleYellowCard({ card }) {
  return (
    <Tooltip
      tip={
        "2nd Yellow card: " + card?.playerName + " " + (card?.timeMin + 1) + "'"
      }
    >
      <div className="w-[10px] h-[15px] bg-yellow-300 ml-1"></div>
      <div className="w-[10px] h-[15px] bg-red-400 ml-[-6px] mt-[2px]"></div>
    </Tooltip>
  );
}

export function RedCard({ card }) {
  return (
    <Tooltip
      tip={"Red card: " + card?.playerName + " " + (card?.timeMin + 1) + "'"}
    >
      <div className="w-[10px] h-[15px] bg-red-400 ml-1"></div>{" "}
    </Tooltip>
  );
}
export function Goal({ goal }) {
  return (
    <Tooltip
      tip={
        goal?.qualifier?.find((q) => q.qualifierId === 28) !== undefined
          ? "Own goal: " + goal?.playerName + " " + (goal?.timeMin + 1) + "'"
          : goal?.qualifier?.findIndex((q) => q.qualifierId === 9) > -1
          ? "Penalty missed: " +
            goal?.playerName +
            " " +
            (goal?.timeMin + 1) +
            "'"
          : "Goal: " + goal?.playerName + " " + (goal?.timeMin + 1) + "'"
      }
    >
      <span
        className={`ml-1
            ${
              goal?.qualifier?.findIndex((q) => q.qualifierId === 9) > -1
                ? "after:content-[attr(data-content)]"
                : "after:content-none"
            }
            after:absolute
            after:w-[10px]
            after:h-[10px]
         
            ${goal?.typeId !== 16 && "after:bg-red-400"}
            ${goal?.typeId === 16 && "after:bg-green-400"}
            after:rounded-full
            after:left-1/2
            after:top-[10px]
            after:text-[6px]
            after:text-white
            after:flex
            after:justify-center
            after:items-center
            after:empty:hidden
            ${
              goal?.qualifier?.findIndex((q) => q.qualifierId === 28) > -1 &&
              "text-red-500"
            }`}
        data-content={
          goal?.qualifier?.findIndex((q) => q.qualifierId === 9) > -1 && "P"
        }
      >
        <GiSoccerBall />
      </span>
    </Tooltip>
  );
}
export function SubOn({ subOff, subOn }) {
  return (
    <Tooltip
      tip={"Sub: " + subOn?.playerName + " On, " + subOff?.playerName + " Off"}
    >
      <span className="text-[#0adb93] ml-1">
        <ChevronUpIcon className="h-4 w-4" />
      </span>
    </Tooltip>
  );
}
export function SubOff({ subOff, subOn }) {
  return (
    <Tooltip
      tip={"Sub: " + subOff?.playerName + " Off, " + subOn?.playerName + " On"}
    >
      <span className="text-[#db0a41] ml-1">
        <ChevronDownIcon className="h-4 w-4" />
      </span>
    </Tooltip>
  );
}
export function Sub({ subOn, subOff }) {
  return (
    <Tooltip
      tip={"Sub: " + subOn?.playerName + " On, " + subOff?.playerName + " Off"}
    >
      <span className="ml-1">
        <ArrowPathIcon className="h-4 w-4" />
      </span>
    </Tooltip>
  );
}

export function Assist({ assist }) {
  return (
    <Tooltip tip={"Assisted goal"}>
      <span className="ml-1 w-[20px] h-[20px] items-center flex justify-center rounded-full border">
        A
      </span>
    </Tooltip>
  );
}
