import React, { useRef } from "react";
//chevron at bottom
// after:absolute
// after:left-1/2 after:-top-3
// after:h-0 after:w-0
// after:-translate-x-1/2 after:border-8
// after:border-t-gray-700
// after:border-l-transparent
// after:border-b-transparent
// after:border-r-transparent
// after:opacity-0
// after:transition-all
// after:pointer-events-none
// after:z-10
function Tooltip({ children, tip, className = "flex" }) {
  const div = useRef();
  return (
    <div
      ref={div}
      className="
      flex 
      relative 
      before:content-[attr(data-tip)] 
      before:absolute
      before:px-3 before:py-2 
      before:left-1/2 before:-bottom-3
      before:w-max before:max-w-xs
      before:-translate-x-1/2 before:-translate-y-full
      before:bg-gray-700 before:text-white 
      before:rounded-md before:opacity-0 
      before:transition-all 
      before:pointer-events-none
      before:z-50
      after:z-50
      "
      data-tip={tip}
    >
      <div
        className={className}
        onMouseOver={() => {
          div.current.classList.add("before:opacity-100");
          div.current.classList.add("after:opacity-100");
        }}
        onMouseOut={() => {
          div.current.classList.remove("before:opacity-100");
          div.current.classList.remove("after:opacity-100");
        }}
      >
        {children}
      </div>
    </div>
  );
}

export default Tooltip;
