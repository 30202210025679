import moment from "moment";
import React, { useContext } from "react";
import { GiSoccerBall } from "react-icons/gi";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { GameEventsContext } from "../../contexts/GameEventContext";
import { classNames } from "../../Utils/Utils";
const all_events = {
  1: "Pass",
  2: "Offside pass",
  3: "Dribble",
  4: "Foul",
  5: "Ball goes out",
  6: "Corner given",
  7: "Tackle",
  8: "Interception",
  10: "Save/Block",
  11: "GK claim",
  12: "Clearance",
  13: "Shot missed",
  14: "Shot hits post",
  15: "Shot saved/blocked",
  16: "Goal",
  17: "Card shown",
  18: "Subbed off",
  19: "Subbed on",
  20: "Player off injured",
  21: "Player back on",
  22: "Outfielder in goal",
  23: "GK plays outfield",
  24: "Condition change",
  25: "Official change",
  27: "Start of delay",
  28: "Delay ends",
  30: "End of period",
  32: "Start of period",
  34: "Team formation",
  35: "Player position change",
  36: "Shirt no. change",
  37: "Opta data ends",
  38: "Temp. goal event",
  39: "Temp. shot event",
  40: "Formation change",
  41: "GK punch",
  42: "Good skill",
  43: "Deleted",
  44: "Aerial duel",
  45: "Dribbled past",
  49: "Ball recovered",
  50: "Dispossessed",
  51: "Mistake",
  52: "GK picks up ball",
  53: "GK misses cross",
  54: "GK smothers",
  55: "Last defender at offside",
  56: "Shield ball out of play",
  57: "Foul throw",
  58: "GK faces penalty",
  59: "Keeper sweeper",
  60: "Didn't connect for shot",
  61: "Touch",
  63: "Temp. save/block",
  64: "Abandoned match resumes",
  65: "Controversial decision",
  67: "50/50",
  68: "Referee drop ball",
  70: "Injury time announced",
  71: "Coaching staff info",
  74: "Player blocks pass",
  75: "Kick-off delayed",
  76: "Match ends early",
  79: "Opta lost coverage",
  80: "Keeper drops ball to play out",
  81: "Ball Hits obstacle",
  82: "Player has ball",
  83: "Attempted tackle",
  84: "Deleted after VAR check",
};

const qualifiers = {
  34: "Referee abuse",
  35: "Argument",
  36: "Violent conduct",
  37: "Time wasting",
  38: "Excessive celebration",
  39: "Crowd interation",
  392: "Reckless offence",
};
const key_events = [13, 14, 15, 16, 17, 18, 19, 42, 60, 65, 30, 32];

function getTime({ event }) {
  const periods = { 1: 45, 2: 90 };
  let mins =
    event?.timeMin > periods[event.periodId]
      ? periods[event.periodId] +
        "+" +
        (event?.timeMin - periods[event.periodId])
      : event?.timeMin;
  return (
    mins?.toString()?.padStart(2, "0") +
    ":" +
    event?.timeSec?.toString()?.padStart(2, "0")
  );
}

function EventList({ game }) {
  const { events: opta_events } = useContext(GameEventsContext);

  return (
    <div className="flex flex-col w-full mt-4">
      <div className="flex w-full">
        <div className="flex w-1/2 justify-center">{game?.home_team?.name}</div>
        <div className="w-1/6"></div>
        <div className="flex w-1/2 justify-center">{game?.away_team?.name}</div>
      </div>
      <div className="flex flex-col w-full h-[calc(100vh-300px)] overflow-y-auto">
        {opta_events
          ?.filter((e) => {
            return (
              key_events?.indexOf(e.typeId) > -1 ||
              (e.typeId === 4 &&
                e.qualifier?.findIndex((q) => e.qualifierId === 390) > -1)
            );
          })
          ?.filter((e) => {
            return (
              (e.typeId !== 30 && e.typeId !== 32) ||
              (e.typeId === 30 &&
                e.contestantId === game?.home_team?.opta_ID) ||
              (e.typeId === 32 && e.contestantId === game?.home_team?.opta_ID)
            );
          })
          ?.filter((e) => {
            //remove end of collection event
            return (
              e.qualifier?.findIndex((q) => q.qualifierId === 57) === -1 ||
              e.qualifier?.find((q) => q.qualifierId === 57)?.value === "0"
            );
          })
          ?.reverse()
          ?.map((event) => {
            let related_event = opta_events?.find(
              (e) =>
                e.eventId ===
                  parseInt(
                    event?.qualifier?.find((q) => q?.qualifierId === 55)?.value
                  ) && e.contestantId === event.contestantId
            );

            return (
              <div className="flex border-b align-middle p-4">
                <div className="flex w-full">
                  <div className="flex w-1/2 justify-end">
                    {event?.contestantId === game?.home_team?.opta_ID && (
                      <div className="flex items-center">
                        <p className="text-sm text-gray-500 dark:text-gray-200">
                          <span class="font-medium text-gray-900 dark:text-gray-100">
                            {event?.playerName}{" "}
                          </span>
                          {event?.typeId !== 15 && all_events[event?.typeId]}
                          {event?.typeId === 15 &&
                            event?.qualifier?.findIndex(
                              (q) => q?.qualifierId === 82
                            ) > -1 &&
                            "Shot blocked"}
                          {event?.typeId === 15 &&
                            event?.qualifier?.findIndex(
                              (q) => q?.qualifierId === 82
                            ) === -1 &&
                            "Shot saved"}
                          {event?.qualifier
                            ?.filter((q) => {
                              return qualifiers[q?.qualifierId];
                            })
                            ?.map((q) => " - " + qualifiers[q?.qualifierId])}

                          {event?.qualifier?.findIndex((q) => {
                            return q?.qualifierId === 29;
                          }) > -1 && " - Assist: " + related_event?.playerName}
                        </p>
                        <EventIcon event={event} />
                      </div>
                    )}
                  </div>

                  <div className="w-1/6 flex flex-col items-center">
                    {getTime({ event })}
                    <span className="text-xs text-gray-500">
                      {moment(event.timeStamp)
                        ?.add(1, "hours")
                        ?.format("HH:mm:ss")}
                    </span>
                  </div>

                  <div className="flex w-1/2">
                    {event?.contestantId === game?.away_team?.opta_ID && (
                      <div className="flex items-center">
                        <EventIcon event={event} />
                        <p className="text-sm text-gray-500 dark:text-gray-200">
                          <span class="font-medium text-gray-900 dark:text-gray-100">
                            {event?.playerName}
                          </span>{" "}
                          {event?.typeId !== 15 && all_events[event?.typeId]}
                          {event?.typeId === 15 &&
                            event?.qualifier?.findIndex(
                              (q) => q?.qualifierId === 82
                            ) > -1 &&
                            "Shot blocked"}
                          {event?.typeId === 15 &&
                            event?.qualifier?.findIndex(
                              (q) => q?.qualifierId === 82
                            ) === -1 &&
                            "Shot saved"}
                          {event?.qualifier
                            ?.filter((q) => {
                              return qualifiers[q?.qualifierId];
                            })
                            ?.map((q) => " - " + qualifiers[q?.qualifierId])}
                          {event?.qualifier?.findIndex((q) => {
                            return q?.qualifierId === 29;
                          }) > -1 && " - Assist: " + related_event?.playerName}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

function EventIcon({ event }) {
  return (
    <div className="flex m-2">
      {event?.typeId === 16 && <GiSoccerBall />}
      {event?.typeId === 17 &&
        event?.qualifier?.findIndex((q) => q?.qualifierId === 31) > -1 && (
          <YellowCard />
        )}
      {event?.typeId === 17 &&
        event?.qualifier?.findIndex((q) => q?.qualifierId === 32) > -1 && (
          <>
            <YellowCard />
            <YellowCard />
          </>
        )}

      {event?.typeId === 17 &&
        event?.qualifier?.findIndex((q) => q?.qualifierId === 33) > -1 && (
          <RedCard />
        )}

      {event?.typeId === 18 && <FaChevronDown className="text-red-500" />}
      {event?.typeId === 19 && <FaChevronUp className="text-green-500" />}
    </div>
  );
}

function YellowCard() {
  return <div className="w-[10px] h-[15px] bg-yellow-300 mr-1"></div>;
}

function RedCard() {
  return <div className="w-[10px] h-[15px] bg-red-400"></div>;
}

export default React.memo(EventList);
