import React, { useContext, useState, useEffect } from "react";
import HyperLogo from "../../Images/HyperLogo.png";
// import styled from "styled-components";
import Games from "../Games/Games";
// import Live from "./Live";
import EFL from "./EFL";
import { useParams, Switch, Route } from "react-router-dom";
import { UIContext, VIEW_CONSTS } from "../../contexts/UIContext";
import LeftBar from "../LeftBar/LeftBar";
import { MoonIcon, SunIcon } from "@heroicons/react/24/outline";
import { UserContext } from "../../contexts/UserContext";

export default function Main() {
  const { signOut, user } = useContext(UserContext);
  const { view, setView } = useContext(UIContext);
  const [selectedPlayer, setSelectedPlayer] = useState();
  const [selectedSubPlayer, setSelectedSubPlayer] = useState();
  const [mode, setMode] = useState("light");
  let { gameID } = useParams();

  useEffect(() => {
    if (gameID) {
      // setSelectedGameID(gameID);
      if (view === VIEW_CONSTS.matchSelect) {
        setView(VIEW_CONSTS.live);
      }
    } else {
      setView(VIEW_CONSTS.matchSelect);
      //setSelectedGame(null);
    }
  }, [gameID]);

  return (
    <div class="min-h-full w-screen dark:bg-gray-950 text-slate-500 dark:text-gray-200">
      <div class="flex border-b border-gray-200 dark:border-none bg-white dark:bg-gray-900 min-h-14 items-center pr-5">
        <div class="max-w px-4">
          <div class="flex  align-middle">
            <div class="flex">
              <div class="flex flex-shrink-0 items-center">
                <img class="h-4" src={HyperLogo} alt="Hyper" />
              </div>
            </div>
          </div>
        </div>

        <div class="ml-auto mr-5 flex items-center">
          <span className="mr-5 text-gray-400">{user?.username}</span>
          <span className="mr-5 text-gray-400 cursor-pointer">
            {mode === "light" && (
              <SunIcon
                className="w-5 h-5"
                onClick={() => {
                  document.documentElement.classList.add("dark");
                  setMode("dark");
                }}
              />
            )}
            {mode === "dark" && (
              <MoonIcon
                className="w-5 h-5"
                onClick={() => {
                  document.documentElement.classList.remove("dark");
                  setMode("light");
                }}
              />
            )}
          </span>
          <button
            onClick={() => {
              signOut();
            }}
            type="button"
            className="rounded bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Sign out
          </button>
        </div>
      </div>
      <div class="w-screen m-0 min-h-full flex">
        <LeftBar />
        <Switch>
          <Route path="/:gameID">
            <EFL
              setSelectedPlayer={setSelectedPlayer}
              selectedPlayer={selectedPlayer}
              setSelectedSubPlayer={setSelectedSubPlayer}
              selectedSubPlayer={selectedSubPlayer}
              view={view}
              setView={setView}
            />
          </Route>
          <Route path="/">
            <Games
              onSelectGame={(game) => {
                // setSelectedGame(game);
              }}
            />
          </Route>
        </Switch>
      </div>
      {/* //       <Content>
//         <Switch>
//           <Route path="/:gameID">
//             <LeftBar>
//               <LeftSideBar view={VIEW_CONSTS.live} />
//             </LeftBar>
//             {(window.ENV?.REACT_APP_IMG || process.env.REACT_APP_IMG) && (
//               <EFL
//                 setSelectedPlayer={setSelectedPlayer}
//                 selectedPlayer={selectedPlayer}
//                 setSelectedSubPlayer={setSelectedSubPlayer}
//                 selectedSubPlayer={selectedSubPlayer}
//                 view={view}
//                 setView={setView}
//               />
//             )}
//             {!window.ENV?.REACT_APP_IMG && !process.env.REACT_APP_IMG && (
//               <Live
//                 setSelectedPlayer={setSelectedPlayer}
//                 selectedPlayer={selectedPlayer}
//                 view={view}
//                 setView={setView}
//               />
//             )}
//           </Route>
//           <Route path="/">
//             <Column>
//               {!window.hideHeader && <NavBar />}

//               <MainDiv>
//                 <LeftBar>
//                   <LeftSideBar view={VIEW_CONSTS.matchSelect} />
//                 </LeftBar>
//                 <Games
//                   onSelectGame={(game) => {
//                     // setSelectedGame(game);
//                   }}
//                 />
//               </MainDiv>
//             </Column>
//           </Route>
//         </Switch>
//       </Content> */}
    </div>
  );
}
