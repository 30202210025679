import React, { useContext, useEffect, useState } from "react";
import Pitch from "../../Images/LargePitch.png";
import { GameEventsContext } from "../../contexts/GameEventContext";
import { UserContext } from "../../contexts/UserContext";
import { classNames } from "../../Utils/Utils";
function ZonesOfControl({ game }) {
  const { user } = useContext(UserContext);
  const { processedTotalEvent, processedFirstEvent, processedSecondEvent } =
    useContext(GameEventsContext);
  const [half, setHalf] = useState(1);
  const [view, setView] = useState(1);
  const [stats, setStats] = useState([]);
  const [leftTeam, setLeftTeam] = useState();
  const [rightTeam, setRightTeam] = useState();
  useEffect(() => {
    
    if (view === 1) {
      setStats(processedFirstEvent);
      setHalf(1);
    } else if (view === 2) {
      setStats(processedSecondEvent);
      setHalf(2);
    } else if (view === 0) {
      setStats(processedTotalEvent);
      setHalf(2);
    }
    
  }, [view, processedTotalEvent, processedFirstEvent, processedSecondEvent]);
  useEffect(() =>{
    let kick_off = stats?.["kick_off_" + half]
    let left_team =
    kick_off?.[game?.home_team?.opta_ID] === "Left to Right" ||
    kick_off?.[game?.away_team?.opta_ID] === "Right to Left"
      ? game?.home_team
      : game?.away_team;
  let right_team =
    kick_off?.[game?.home_team?.opta_ID] === "Left to Right" ||
    kick_off?.[game?.away_team?.opta_ID] === "Right to Left"
      ? game?.away_team
      : game?.home_team;
setLeftTeam(left_team)
setRightTeam(right_team)
  }, [stats, view])
  const [tabs, setTabs] = useState([
    { name: "1st half", id: "1st_half", current: true, value: 1 },
    { name: "2nd half", id: "2nd_half", current: false, value: 2 },
    { name: "Full game", id: "full_game", current: false, value: 0 },
  ]);
  return (
    <div className="justify-center flex-col w-full">
      <div className="border-b border-gray-200">
        <nav aria-label="Tabs" className="-mb-px flex space-x-8">
          {tabs.map((tab) => (
            <a
              key={tab.name}
              onClick={() => {
                let new_tabs = [...tabs];
                new_tabs = new_tabs?.map((t) => {
                  return { ...t, current: false };
                });
                new_tabs.find((t) => t.name === tab.name).current = true;
                setTabs(new_tabs);
                setView(tab.value);
              }}
              aria-current={tab.current ? "page" : undefined}
              className={classNames(
                "cursor-pointer",
                tab.current
                  ? "border-green-500 text-green-600"
                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                "whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
              )}
            >
              {tab.name}
            </a>
          ))}
        </nav>
      </div>
      <div className="flex mt-10">
        <div className="relative w-full">
          <img
            src={Pitch}
            className="w-full filter invert-[40%] sepia-[14%] saturate-[391%]"
          />
          <div className="flex w-full absolute top-1">
           <div className="flex w-1/2 justify-center">
           <img
                    className="w-100 opacity-10 "
                    src={
                      (window.ENV?.REACT_APP_PROJECT_SERVER ||
                        process.env.REACT_APP_PROJECT_SERVER) +
                      "/api/assets/images/" +
                      (user?.teams?.[0]?.owner ?? user?._id) +
                      "/" +
                      leftTeam?.variables?.badge
                    }
                    alt="Team Badge"
                  />
           </div>
           <div className="flex w-1/2 justify-center">
           <img
                    className="w-100 opacity-10 "
                    src={
                      (window.ENV?.REACT_APP_PROJECT_SERVER ||
                        process.env.REACT_APP_PROJECT_SERVER) +
                      "/api/assets/images/" +
                      (user?.teams?.[0]?.owner ?? user?._id) +
                      "/" +
                      rightTeam?.variables?.badge
                    }
                    alt="Team Badge"
                  />
           </div>
           </div>
          <div className="flex-col w-full h-full absolute top-0">
            <ZoneRow
              stats={stats}
              game={game}
              row="1"
              half={half}
              processedTotalEvent={processedTotalEvent}
              view={view}
            />
            <ZoneRow
              stats={stats}
              game={game}
              row="2"
              half={half}
              processedTotalEvent={processedTotalEvent}
              view={view}
            />
            <ZoneRow
              stats={stats}
              game={game}
              row="3"
              half={half}
              processedTotalEvent={processedTotalEvent}
              view={view}
            />
            <ZoneRow
              stats={stats}
              game={game}
              row="4"
              half={half}
              processedTotalEvent={processedTotalEvent}
              view={view}
            />
            <ZoneRow
              stats={stats}
              game={game}
              row="5"
              half={half}
              processedTotalEvent={processedTotalEvent}
              view={view}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function ZoneRow({ row, half, processedTotalEvent, game, stats, view }) {
  return (
    <div
      className="flex w-full"
      style={{ height: row === 1 || row === 5 ? "23%" : "20%" }}
    >
      <Zone
        stats={stats}
        game={game}
        column="1"
        row={row}
        kick_off={processedTotalEvent?.["kick_off_" + half]}
        view={view}
      ></Zone>
      <Zone
        stats={stats}
        game={game}
        column="2"
        row={row}
        kick_off={processedTotalEvent?.["kick_off_" + half]}
        view={view}
      ></Zone>
      <Zone
        stats={stats}
        game={game}
        column="3"
        row={row}
        kick_off={processedTotalEvent?.["kick_off_" + half]}
        view={view}
      ></Zone>
      <Zone
        stats={stats}
        game={game}
        column="4"
        row={row}
        kick_off={processedTotalEvent?.["kick_off_" + half]}
        view={view}
      ></Zone>
      <Zone
        stats={stats}
        game={game}
        column="5"
        row={row}
        kick_off={processedTotalEvent?.["kick_off_" + half]}
        view={view}
      ></Zone>
      <Zone
        stats={stats}
        game={game}
        column="6"
        row={row}
        kick_off={processedTotalEvent?.["kick_off_" + half]}
        view={view}
      ></Zone>
    </div>
  );
}

function Zone({ stats, game, row, column, kick_off, view }) {
  const { user } = useContext(UserContext);

  let left_team =
    kick_off?.[game?.home_team?.opta_ID] === "Left to Right" ||
    kick_off?.[game?.away_team?.opta_ID] === "Right to Left"
      ? game?.home_team
      : game?.away_team;
  let right_team =
    kick_off?.[game?.home_team?.opta_ID] === "Left to Right" ||
    kick_off?.[game?.away_team?.opta_ID] === "Right to Left"
      ? game?.away_team
      : game?.home_team;
  let left_value =
    stats?.[left_team?.opta_ID]?.zone_touches?.[column + ":" + row];
  let right_value =
    stats?.[right_team?.opta_ID]?.zone_touches?.[7 - column + ":" + (6 - row)];
  let percent = (left_value / (left_value + right_value)) * 100;
  
  return (
    <div
      className="flex items-center justify-center"
      style={{
        width: column === "1" || column === "6" ? "33%" : "30%",
        opacity: 0.8,
      }}
    >
      {percent > 55 && (
        <img
          className="w-[60%]"
          src={
            (window.ENV?.REACT_APP_PROJECT_SERVER ||
              process.env.REACT_APP_PROJECT_SERVER) +
            "/api/assets/images/" +
            (user?.teams?.[0]?.owner ?? user?._id) +
            "/" +
            left_team?.variables?.badge
          }
          alt="Team Badge"
        />
      )}
      {percent < 45 && (
        <img
          className="w-[60%]"
          src={
            (window.ENV?.REACT_APP_PROJECT_SERVER ||
              process.env.REACT_APP_PROJECT_SERVER) +
            "/api/assets/images/" +
            (user?.teams?.[0]?.owner ?? user?._id) +
            "/" +
            right_team?.variables?.badge
          }
          alt="Team Badge"
        />
      )}
    </div>
  );
}

export default ZonesOfControl;
