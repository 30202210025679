import moment from "moment";
import React, { useContext } from "react";

import {
  Assist,
  DoubleYellowCard,
  Goal,
  RedCard,
  SubOff,
  SubOn,
  YellowCard,
} from "../EventIcons/EventIcons";
import { PrinterIcon } from "@heroicons/react/24/outline";
import { UIContext } from "../../contexts/UIContext";
function EFLLineup({
  game,
  team,
  team_type = "home",
  lineup,
  optaEvents,
  allOPTAEvents,
  printer,
  print,
}) {
  const { selectedPlayers, setSelectedPlayers } = useContext(UIContext);
  let number_of_players_on_pitch =
    game?.competition?.competition_type === "beach" ? 5 : 11;
  let number_of_players_in_squad =
    game?.competition?.competition_type === "beach" ? 12 : 16;

  return (
    <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-900 ">
      <tbody className="bg-white dark:bg-gray-800 dark:text-gray-100 text-gray-900">
        <tr>
          <td colSpan={5} className="text-sm p-1 border-t border-b">
            <div className="flex">
              {team?.name}
              {printer && (
                <span className="ml-auto">
                  <PrinterIcon
                    className="h-5 w-5 cursor-pointer"
                    onClick={print}
                  />
                </span>
              )}
            </div>
          </td>
        </tr>
        {new Array(number_of_players_on_pitch)
          .fill(1)
          ?.sort((a, b) => {
            return a?.order - b?.order;
          })
          .map((item, index) => {
            let l = lineup?.find((l) => l.order === index + 1);
            let player = {
              _id: l?.squad?._id,
              ...l?.squad?.player,
              order: l?.order,
              shirt_number:
                l?.squad?.shirt_number || l?.squad?.opta_shirt_number,
              squad: l?.squad,
              yellow_card: l?.yellow_card,
              dbl_yellow_card: l?.dbl_yellow_card,
              red_card: l?.red_card,
              sub_order: l?.sub_order,
              subbed: l?.subbed,
              captain: l?.captain,
            };
            return (
              <tr
                key={player._id}
                className="even:bg-gray-50 dark:even:bg-gray-900"
                onClick={() => {
                  let new_players = [...selectedPlayers];
                  new_players = new_players.filter(
                    (p) => p?.opta_ID !== player?.opta_ID
                  );
                  new_players.push(player);
                  setSelectedPlayers(new_players);
                }}
              >
                <td className="h-9 whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium  sm:pl-3">
                  {player.shirt_number}
                </td>
                <td className="whitespace-nowrap pl-4 pr-3 text-sm font-medium sm:pl-3">
                  <span className="text-gray-500 dark:text-gray-400">
                    {player.first_name ?? player.opta_first_name}
                  </span>{" "}
                  {player.last_name ?? player.opta_last_name}
                </td>
                <td className="whitespace-nowrap px-3 text-sm text-gray-500">
                  <div className="flex items-center">
                    <ProcessEvents
                      events={optaEvents}
                      allOPTAEvents={allOPTAEvents}
                      player={player}
                      team={team}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        <tr>
          <td colSpan={5} className="text-sm p-2 border-t border-b">
            Substitutes
          </td>
        </tr>
        {new Array(Math.max(lineup?.length - number_of_players_on_pitch, 5))
          ?.fill(1)
          ?.map((item, index) => {
            let l = lineup?.find(
              (l) => l.order === index + 1 + number_of_players_on_pitch
            );

            let player = {
              _id: l?.squad?._id,
              ...l?.squad?.player,
              order: l?.order,
              shirt_number:
                l?.squad?.shirt_number || l?.squad?.opta_shirt_number,
              squad: l?.squad,
              yellow_card: l?.yellow_card,
              dbl_yellow_card: l?.dbl_yellow_card,
              red_card: l?.red_card,
              sub_order: l?.sub_order,
              subbed: l?.subbed,
              captain: l?.captain,
            };
            return (
              <tr
                key={player._id}
                className="even:bg-gray-50 dark:even:bg-gray-900"
                onClick={() => {
                  let new_players = [...selectedPlayers];
                  new_players = new_players.filter(
                    (p) => p?.opta_ID !== player.opta_ID
                  );
                  new_players.push(player);
                  setSelectedPlayers(new_players);
                }}
              >
                <td className="h-9 whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                  {player.shirt_number}
                </td>
                <td className="whitespace-nowrap pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                  <span className="text-gray-500">
                    {player.first_name ?? player.opta_first_name}
                  </span>{" "}
                  {player.last_name ?? player.opta_last_name}
                </td>
                <td className="whitespace-nowrap px-3 text-sm text-gray-500">
                  <div className="flex items-center">
                    <ProcessEvents
                      events={optaEvents}
                      allOPTAEvents={allOPTAEvents}
                      player={player}
                      team={team}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
}

function ProcessEvents({ events, player, allOPTAEvents, team }) {
  let player_events = events?.filter((g) => {
    let related_event = allOPTAEvents?.find((e) => {
      return (
        g?.typeId === 16 &&
        e.eventId ===
          parseInt(g?.qualifier?.find((q) => q?.qualifierId === 55)?.value) &&
        (e.playerId === player?.squad?.player?.opta_ID ||
          e?.playerId === player?.squad?._id)
      );
    });

    return (
      ((g?.player || g?.playerId) &&
        (g?.player === player?.squad?._id ||
          g?.playerId === player?.squad?.player?.opta_ID)) ||
      (g?.typeId === 16 &&
        (related_event?.playerId === player?.squad?.player?.opta_ID ||
          related_event?.playerId === player?.squad?._id))
    );
  });

  return player_events?.map((ev) => {
    if (
      (((ev?.player === player?.squad?._id ||
        ev?.playerId === player?.squad?.player?.opta_ID) &&
        ev.typeId === 16) ||
        ((ev.typeId === 13 || ev.typeId === 14 || ev.typeId === 15) &&
          ev.qualifier.findIndex((q) => q.qualifierId === 9) > -1)) &&
      ev?.periodId !== 5
    ) {
      return <Goal goal={ev} />;
    }
    if (
      ev.typeId === 17 &&
      ev.qualifier.findIndex((q) => q.qualifierId === 31) > -1
    ) {
      return <YellowCard card={ev} />;
    }
    if (
      ev.typeId === 17 &&
      ev.qualifier.findIndex((q) => q.qualifierId === 32) > -1
    ) {
      return <DoubleYellowCard card={ev} />;
    }

    if (
      ev.typeId === 17 &&
      ev.qualifier.findIndex((q) => q.qualifierId === 33) > -1
    ) {
      return <RedCard card={ev} />;
    }

    if (ev.typeId === 18) {
      let related_event = allOPTAEvents?.find(
        (e) =>
          e.contestantId === team.opta_ID &&
          e.eventId ===
            parseInt(ev?.qualifier?.find((q) => q?.qualifierId === 55)?.value)
      );
      return <SubOff subOff={ev} subOn={related_event} />;
    }
    if (ev.typeId === 19) {
      let related_event = allOPTAEvents?.find(
        (e) =>
          e.contestantId === team.opta_ID &&
          e.eventId ===
            parseInt(ev?.qualifier?.find((q) => q?.qualifierId === 55)?.value)
      );
      return <SubOn subOn={ev} subOff={related_event} />;
    }

    if (
      ev.typeId === 16 &&
      ev?.periodId !== 5 &&
      ev.contestantId === team.opta_ID
    ) {
      let related_event = allOPTAEvents?.find(
        (e) =>
          e.contestantId === team.opta_ID &&
          e.eventId ===
            parseInt(ev?.qualifier?.find((q) => q?.qualifierId === 55)?.value)
      );
      return (
        <div>
          <Assist assist={related_event} />
        </div>
      );
    }
  });
}

export default React.memo(EFLLineup);
