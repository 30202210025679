import React, { useContext, useEffect, useState } from "react";
import Pitch from "../../Images/LargePitch.png";
import { GameEventsContext } from "../../contexts/GameEventContext";
import { UserContext } from "../../contexts/UserContext";
import { classNames } from "../../Utils/Utils";
function ActionAreas({ game }) {
  const { user } = useContext(UserContext);
  const { processedTotalEvent, processedFirstEvent, processedSecondEvent } =
    useContext(GameEventsContext);
  const [half, setHalf] = useState(1);
  const [stats, setStats] = useState([]);
  useEffect(() => {
    if (half === 1) {
      setStats(processedFirstEvent);
    } else if (half === 2) {
      setStats(processedSecondEvent);
    } else if (half === 0) {
      setStats(processedTotalEvent);
    }
  }, [half, processedTotalEvent, processedFirstEvent, processedSecondEvent]);
  const [tabs, setTabs] = useState([
    { name: "1st half", id: "1st_half", current: true, value: 1 },
    { name: "2nd half", id: "2nd_half", current: false, value: 2 },
    { name: "Full game", id: "full_game", current: false, value: 0 },
  ]);
  return (
    <div className="justify-center flex-col w-full">
      <div className="border-b border-gray-200">
        <nav aria-label="Tabs" className="-mb-px flex space-x-8">
          {tabs.map((tab) => (
            <a
              key={tab.name}
              onClick={() => {
                let new_tabs = [...tabs];
                new_tabs = new_tabs?.map((t) => {
                  return { ...t, current: false };
                });
                new_tabs.find((t) => t.name === tab.name).current = true;
                setTabs(new_tabs);
                setHalf(tab.value);
              }}
              aria-current={tab.current ? "page" : undefined}
              className={classNames(
                "cursor-pointer",
                tab.current
                  ? "border-green-500 text-green-600"
                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                "whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
              )}
            >
              {tab.name}
            </a>
          ))}
        </nav>
      </div>
      <div className="flex mt-10">
        <div className="relative w-full">
          {half > 0 && (
            <div className="flex w-full">
              <div className="flex w-1/2 justify-center">
                {processedTotalEvent?.["kick_off_" + half]?.[
                  game?.home_team?.opta_ID
                ] === "Left to Right" ||
                processedTotalEvent?.["kick_off_" + half]?.[
                  game?.away_team?.opta_ID
                ] === "Right to Left"
                  ? game?.home_team?.code
                  : game?.away_team?.code}
              </div>

              <div className="flex w-1/2 justify-center">
                {processedTotalEvent?.["kick_off_" + half]?.[
                  game?.home_team?.opta_ID
                ] === "Left to Right" ||
                processedTotalEvent?.["kick_off_" + half]?.[
                  game?.away_team?.opta_ID
                ] === "Right to Left"
                  ? game?.away_team?.code
                  : game?.home_team?.code}
              </div>
            </div>
          )}
          {half > 0 && (
            <div className="absolute flex w-full top-[10%]">
              <div className="flex w-1/2 justify-center">
                {processedTotalEvent?.["kick_off_" + half]?.[
                  game?.home_team?.opta_ID
                ] === "Left to Right" ||
                processedTotalEvent?.["kick_off_" + half]?.[
                  game?.away_team?.opta_ID
                ] === "Right to Left" ? (
                  <img
                    className="w-100 opacity-10"
                    src={
                      (window.ENV?.REACT_APP_PROJECT_SERVER ||
                        process.env.REACT_APP_PROJECT_SERVER) +
                      "/api/assets/images/" +
                      (user?.teams?.[0]?.owner ?? user?._id) +
                      "/" +
                      game?.home_team?.variables?.badge
                    }
                    alt="Team Badge"
                  />
                ) : (
                  <img
                    className="w-100 opacity-10"
                    src={
                      (window.ENV?.REACT_APP_PROJECT_SERVER ||
                        process.env.REACT_APP_PROJECT_SERVER) +
                      "/api/assets/images/" +
                      (user?.teams?.[0]?.owner ?? user?._id) +
                      "/" +
                      game?.away_team?.variables?.badge
                    }
                    alt="Team Badge"
                  />
                )}
              </div>

              <div className="flex w-1/2 justify-center">
                {processedTotalEvent?.["kick_off_" + half]?.[
                  game?.home_team?.opta_ID
                ] === "Left to Right" ||
                processedTotalEvent?.["kick_off_" + half]?.[
                  game?.away_team?.opta_ID
                ] === "Right to Left" ? (
                  <img
                    className="w-100  opacity-10"
                    src={
                      (window.ENV?.REACT_APP_PROJECT_SERVER ||
                        process.env.REACT_APP_PROJECT_SERVER) +
                      "/api/assets/images/" +
                      (user?.teams?.[0]?.owner ?? user?._id) +
                      "/" +
                      game?.away_team?.variables?.badge
                    }
                    alt="Team Badge"
                  />
                ) : (
                  <img
                    className="w-100 opacity-10"
                    src={
                      (window.ENV?.REACT_APP_PROJECT_SERVER ||
                        process.env.REACT_APP_PROJECT_SERVER) +
                      "/api/assets/images/" +
                      (user?.teams?.[0]?.owner ?? user?._id) +
                      "/" +
                      game?.home_team?.variables?.badge
                    }
                    alt="Team Badge"
                  />
                )}
              </div>
            </div>
          )}
          <div
            className="absolute flex w-full top-[40%] text-8xl font-bold"
            style={{
              flexDirection:
                processedTotalEvent?.["kick_off_" + half]?.[
                  game?.home_team?.opta_ID
                ] === "Left to Right" ||
                processedTotalEvent?.["kick_off_" + half]?.[
                  game?.away_team?.opta_ID
                ] === "Right to Left"
                  ? "row"
                  : "row-reverse",
            }}
          >
            <div className="flex w-1/3 justify-center">
              {Math.round(
                ((stats?.[game?.home_team?.opta_ID]?.defensive_third_home +
                  stats?.[game?.away_team?.opta_ID]?.attacking_third_away) /
                  stats?.action_areas_total) *
                  100
              ) || 0}
              %
            </div>
            <div className="flex w-1/3 justify-center">
              {Math.round(
                ((stats?.[game?.home_team?.opta_ID]?.middle_third_home +
                  stats?.[game?.away_team?.opta_ID]?.middle_third_away) /
                  stats?.action_areas_total) *
                  100
              ) || 0}
              %
            </div>
            <div className="flex w-1/3 justify-center">
              {100 -
                ((Math.round(
                  ((stats?.[game?.home_team?.opta_ID]?.defensive_third_home +
                    stats?.[game?.away_team?.opta_ID]?.attacking_third_away) /
                    stats?.action_areas_total) *
                    100
                ) || 0) +
                  (Math.round(
                    ((stats?.[game?.home_team?.opta_ID]?.middle_third_home +
                      stats?.[game?.away_team?.opta_ID]?.middle_third_away) /
                      stats?.action_areas_total) *
                      100
                  ) || 0))}
              %
            </div>
          </div>
          <img
            src={Pitch}
            className="w-full filter invert-[40%] sepia-[14%] saturate-[391%]"
          />
        </div>
      </div>
    </div>
  );
}

export default ActionAreas;
