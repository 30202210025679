import React, { useEffect, useRef, useState } from "react";
import HyperLogo from "../../Images/HyperLogo.png";
import Panel from "../UI/Panel/Panel";
import gql from "graphql-tag";
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  useMutation,
  useQuery,
} from "@apollo/client";

import { setContext } from "@apollo/client/link/context";

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    },
  };
});

const httpLink = createHttpLink({
  uri:
    (window.ENV?.REACT_APP_PROJECT_SERVER ||
      process.env.REACT_APP_PROJECT_SERVER) + "/graphql",
});

const user_client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});

const LOGIN = gql`
  mutation Login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      token
      _id
    }
  }
`;

const LOGIN_WITH_TOKEN = gql`
  mutation LoginWithToken($token: String!) {
    loginWithToken(token: $token) {
      token
      _id
    }
  }
`;

export default function Login({ loggedIn }) {
  const [error, setError] = useState();
  const [login, { data }] = useMutation(LOGIN, {
    client: user_client,
    onCompleted: (data) => {
      setError();
      localStorage.setItem("user-id", data.login._id);
      localStorage.setItem("token", data.login.token);
      loggedIn();
    },
    onError: (err) => {
      setError(err.message);
    },
  });
  const [loginWithToken, { data: token_data }] = useMutation(LOGIN_WITH_TOKEN, {
    client: user_client,
    onCompleted: (data) => {
      setError();
      localStorage.setItem("user-id", data.loginWithToken._id);
      localStorage.setItem("token", data.loginWithToken.token);
      loggedIn();
    },
    onError: (err) => {
      setError(err.message);
    },
  });
  const usernameRef = useRef();
  const passwordRef = useRef();
  const buttonRef = useRef();

  useEffect(() => {
    let callback = (event) => {
      try {
        let data = JSON.parse(event?.data);
        if (data?.event === "login") {
          loginWithToken({ variables: { token: data.data.token } });
        }
      } catch (err) {}
    };
    window.addEventListener("message", callback);
    return () => {
      window.removeEventListener("message", callback);
    };
  }, []);

  return (
    <div className="flex flex-col items-center bg-gray-50 h-screen pt-32">
      <img class="h-6" src={HyperLogo} alt="Hyper" />

      <Panel className="w-1/6">
        <div className="flex flex-col items-center w-full">
          <div className="w-full my-2">
            <label
              htmlFor="username"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Username
            </label>

            <input
              ref={usernameRef}
              id="username"
              name="username"
              type="text"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
          <div className="w-full">
            <label
              htmlFor="password"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Password
            </label>

            <input
              ref={passwordRef}
              id="password"
              name="password"
              type="password"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>

          <button
            ref={buttonRef}
            onClick={() => {
              login({
                variables: {
                  username: usernameRef.current.value,
                  password: passwordRef.current.value,
                },
              });
            }}
            type="button"
            password
            className="mt-5 w-full rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Login
          </button>
        </div>
      </Panel>
      {error && (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">{error}</h3>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

// import React, { useRef, useContext, useEffect } from "react";
// import styled from "styled-components";
// import gql from "graphql-tag";
// import {
//   ApolloClient,
//   createHttpLink,
//   InMemoryCache,
//   useMutation,
//   useQuery,
// } from "@apollo/client";

// import { setContext } from "@apollo/client/link/context";

// const authLink = setContext((_, { headers }) => {
//   // get the authentication token from local storage if it exists
//   const token = localStorage.getItem("token");
//   // return the headers to the context so httpLink can read them
//   return {
//     headers: {
//       ...headers,
//       authorization: token ? `${token}` : "",
//     },
//   };
// });

// const httpLink = createHttpLink({
//   uri:
//     (window.ENV?.REACT_APP_PROJECT_SERVER ||
//       process.env.REACT_APP_PROJECT_SERVER) + "/graphql",
// });

// const user_client = new ApolloClient({
//   cache: new InMemoryCache(),
//   link: authLink.concat(httpLink),
// });

// const LOGIN = gql`
//   mutation Login($username: String!, $password: String!) {
//     login(username: $username, password: $password) {
//       token
//       _id
//     }
//   }
// `;

// const LOGIN_WITH_TOKEN = gql`
//   mutation LoginWithToken($token: String!) {
//     loginWithToken(token: $token) {
//       token
//       _id
//     }
//   }
// `;

// const Container = styled.div`
//   width: 100vw;
//   height: 100vh;
//   position: absolute;
//   top: 0;
//   left: 0;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: rgb(14, 18, 25);
//   opacity: 1;
//   transition: 0.5s opacity;
//   z-index: 100;
// `;

// const H1 = styled.h1`
//   font-size: 2em;
//   font-weight: 100;
// `;
// const Main = styled.div`
//   width: 300px;
//   margin-top: -24%;
// `;
// const Form = styled.form`
//   display: flex;
//   flex-direction: column;
// `;
// const Input = styled.input`
//   background-color: #1f222b;
//   border-radius: 4px;
//   border: none;
//   padding: 1em;
//   margin-bottom: 1em;
//   outline: none;
//   color: #fff;
// `;
// const Button = styled.div`
//   background-color: #c11938;
//   padding: 1em;
//   border-radius: 4px;
//   min-width: 80px;
//   display: flex;
//   justify-content: center;
//   cursor: pointer;
//   margin-top: 2em;
// `;
// const Error = styled.div`
//   color: #c11938;
//   padding: 1em;
//   display: flex;
//   justify-content: center;
// `;
//export default function Login({ loggedIn }) {
//   const [login, { data }] = useMutation(LOGIN, {
//     client: user_client,
//     onCompleted: (data) => {
//       localStorage.setItem("user-id", data.login._id);
//       localStorage.setItem("token", data.login.token);
//       loggedIn();
//     },
//   });
//   const [loginWithToken, { data: token_data }] = useMutation(LOGIN_WITH_TOKEN, {
//     client: user_client,
//     onCompleted: (data) => {
//       localStorage.setItem("user-id", data.loginWithToken._id);
//       localStorage.setItem("token", data.loginWithToken.token);
//       loggedIn();
//     },
//   });
//   const usernameRef = useRef();
//   const passwordRef = useRef();
//   const buttonRef = useRef();

//   useEffect(() => {
//     let callback = (event) => {
//       try {
//         let data = JSON.parse(event?.data);
//         if (data?.event === "login") {
//           loginWithToken({ variables: { token: data.data.token } });
//         }
//       } catch (err) {}
//     };
//     window.addEventListener("message", callback);
//     return () => {
//       window.removeEventListener("message", callback);
//     };
//   }, []);

//return (
//  <div>
//    {/* //     <Container>
//       <Main>
//         <H1>Welcome</H1>

//         <Form>
//           <Input ref={usernameRef} placeholder={"Username"} tabIndex="1" />
//           <Input
//             tabIndex="2"
//             ref={passwordRef}
//             placeholder={"Password"}
//             type={"password"}
//             autoComplete="current-password"
//             onKeyDown={(e) => {
//               if (e.key === "Enter") {
//                 login({
//                   variables: {
//                     username: usernameRef.current.value,
//                     password: passwordRef.current.value,
//                   },
//                 });
//               }
//             }}
//           />
//           <Button
//             tabIndex="3"
//             ref={buttonRef}
//             onClick={() => {
//               login({
//                 variables: {
//                   username: usernameRef.current.value,
//                   password: passwordRef.current.value,
//                 },
//               });
//             }}
//           >
//             Login
//           </Button>
//         </Form>
//       </Main>
// </Container> */}
//   </div>
// );
//}
