import React, { useContext } from "react";
import Panel from "../UI/Panel/Panel";
import {
  DoubleYellowCard,
  Goal,
  RedCard,
  Sub,
  SubOff,
  SubOn,
  YellowCard,
} from "../EventIcons/EventIcons";
import { UserContext } from "../../contexts/UserContext";

function Timeline({ game, optaEvents }) {
  const { user } = useContext(UserContext);
  return (
    <Panel>
      <div className="flex flex-col">
        <div className="flex">
          <img
            className="w-6"
            src={
              (window.ENV?.REACT_APP_PROJECT_SERVER ||
                process.env.REACT_APP_PROJECT_SERVER) +
              "/api/assets/images/" +
              (user?.teams?.[0]?.owner ?? user?._id) +
              "/" +
              game?.home_team?.variables?.badge
            }
            alt="Team Badge"
          />
          {game?.home_team?.code}
        </div>
        <div className="h-[16px]"></div>
        <div className="flex">
          <img
            className="w-6"
            src={
              (window.ENV?.REACT_APP_PROJECT_SERVER ||
                process.env.REACT_APP_PROJECT_SERVER) +
              "/api/assets/images/" +
              (user?.teams?.[0]?.owner ?? user?._id) +
              "/" +
              game?.away_team?.variables?.badge
            }
            alt="Team Badge"
          />
          {game?.away_team?.code}
        </div>
      </div>
      <div className="flex flex-col w-full">
        <div className="flex justify-between w-full h-[16px] rounded-lg bg-green-400 relative mt-[25px] items-center pl-5 pr-5">
          {optaEvents
            ?.filter((ev) => {
              return (
                ev.qualifier.findIndex((q) => q.qualifierId === 9) > -1 ||
                ev.typeId === 16 ||
                ev.typeId === 17 ||
                ev.typeId === 18 ||
                (ev.typeId === 30 &&
                  ev.contestantId === game?.home_team?.opta_ID) ||
                ev.typeId === 70
              );
            })
            ?.map((ev) => {
              return <TLEvent event={ev} game={game} optaEvents={optaEvents} />;
            })}
        </div>
      </div>
    </Panel>
  );
}

function TLEvent({ event, game, optaEvents }) {
  return (
    <div className="flex grow-1 max-w-10">
      <div className="flex flex-col items-center">
        <div className="flex items-end h-[30px]">
          <Icons
            team={game?.home_team}
            other_team={game?.away_team}
            event={event}
            optaEvents={optaEvents}
          />
        </div>
        <div className="text-xs text-white dark:text-gray-900 h-[16px] mt-1 mb-1 items-center justify-center">
          {event.typeId !== 30 && event.typeId !== 70 && event?.timeMin + 1}
          {event.typeId === 30 &&
            event?.periodId === 1 &&
            event.contestantId === game?.home_team?.opta_ID && (
              <div className="rounded-sm bg-white dark:bg-gray-900 mt-[1px] mb-[1px] flex items-center text-green-400 h-[14px] text-[10px] pl-1 pr-1">
                HT
              </div>
            )}
          {event.typeId === 30 &&
            event?.periodId === 2 &&
            event.contestantId === game?.home_team?.opta_ID && (
              <div className="rounded-sm bg-white dark:bg-gray-900 mt-[1px] mb-[1px] flex items-center text-green-400 h-[14px] text-[10px] pl-1 pr-1">
                FT
              </div>
            )}
          {event.typeId === 70 && (
            <div className="rounded-sm bg-white dark:bg-gray-900 mt-[1px] mb-[1px] flex items-center text-green-400 h-[14px] text-[10px] pl-1 pr-1">
              +{event.qualifier?.find((q) => q?.qualifierId === 277)?.value}
            </div>
          )}
        </div>
        <div className="flex h-[30px]">
          <Icons
            team={game?.away_team}
            other_team={game?.home_team}
            event={event}
            optaEvents={optaEvents}
          />
        </div>
      </div>
    </div>
  );
}

function Icons({ team, other_team, event, optaEvents }) {
  return (
    <>
      {((event.contestantId === team?.opta_ID &&
        event.qualifier?.find((q) => q.qualifierId === 28) === undefined) ||
        (event.contestantId === other_team?.opta_ID &&
          event.qualifier?.find((q) => q.qualifierId === 28) !== undefined)) &&
        event?.typeId === 16 && <Goal goal={event} />}
      {event.contestantId === team?.opta_ID &&
        (event.typeId === 13 || event.typeId === 14 || event.typeId === 15) &&
        event.qualifier.findIndex((q) => q.qualifierId === 9) > -1 && (
          <Goal goal={event} />
        )}
      {event.contestantId === team?.opta_ID &&
        event?.typeId === 17 &&
        event?.qualifier?.findIndex((q) => q?.qualifierId === 31) > -1 && (
          <YellowCard card={event} />
        )}
      {event.contestantId === team?.opta_ID &&
        event?.typeId === 17 &&
        event?.qualifier?.findIndex((q) => q?.qualifierId === 32) > -1 && (
          <DoubleYellowCard card={event} />
        )}
      {event.contestantId === team?.opta_ID &&
        event?.typeId === 17 &&
        event?.qualifier?.findIndex((q) => q?.qualifierId === 33) > -1 && (
          <RedCard card={event} />
        )}
      {event.contestantId === team?.opta_ID && event?.typeId === 18 && (
        <Sub
          subOn={event}
          subOff={optaEvents?.find((e) => {
            return (
              e.contestantId === team.opta_ID &&
              e.eventId ===
                parseInt(
                  event?.qualifier?.find((q) => q?.qualifierId === 55)?.value
                )
            );
          })}
        />
      )}
    </>
  );
}

export default React.memo(Timeline);
