import React, { useContext, useEffect, useState } from "react";
import Panel from "../UI/Panel/Panel";
import { GameEventsContext } from "../../contexts/GameEventContext";
import moment from "moment";
const worker1 = new Worker("/EventProcessorWorker.js");

function PlayerStats({ player, onClose, optaEvents, game }) {
  const [status, setStatus] = useState(false);
  const [minimized, setMinimized] = useState(false);
  const [processedEvent, setProcessedEvents] = useState({});
  const { processedTotalEvent } = useContext(GameEventsContext);
  useEffect(() => {
    setStatus(true);
  }, []);

  useEffect(() => {
    // Note: in your actual app code, make sure to check if Home
    // is still mounted before setting state asynchronously!

    if (optaEvents?.length > 0 && game) {
      worker1.postMessage({
        events: optaEvents || [],
        game,
      });

      worker1.onmessage = (e) => {
        setProcessedEvents(e.data);
      };
    }
  }, [optaEvents, game]);

  return (
    <div
      data-status={minimized ? "minimized" : status ? "open" : "closed"}
      className="pointer-events-auto relative w-1/6 data-[status=open]:bottom-0 data-[status=closed]:-bottom-[380px] data-[status=minimized]:-bottom-[300px] min-h-[339px] transition-all data-[status=open]:ease-out-in data-[status=closed]:ease-in-out"
    >
      <Panel className="shadow-xl drop-shadow-xl text-sm transition-all px-0 sm:px-1 py-0 sm:py-0">
        <div className="flex flex-col w-full px-1">
          <div className="flex border-b-2 w-full px-0 py-2">
            <div className="flex-col w-full">
              <div className="flex w-full">
                <span className="font-bold mr-1">
                  {player?.squad?.shirt_number ??
                    player?.squad?.opta_shirt_number}
                </span>
                {player?.first_name ?? player?.opta_first_name}

                <span className="font-bold ml-1">
                  {player?.last_name ?? player?.opta_last_name}
                </span>
              </div>
              {minimized === false && (
                <div className="flex text-xs">
                  <span className="mr-1">
                    Age: {moment().diff(player?.date_of_birth, "years")}
                  </span>

                  {player.height && (
                    <span className="mr-1">- {player?.height}cm</span>
                  )}
                  {player.foot && (
                    <span className="mr-1">- {player?.foot} footed</span>
                  )}
                </div>
              )}
            </div>
            <div className="flex ml-auto gap-x-1">
              {minimized === false && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4 cursor-pointer hover:text-[#4ade80]"
                  onClick={() => {
                    setMinimized(true);
                  }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 4.5h14.25M3 9h9.75M3 13.5h9.75m4.5-4.5v12m0 0-3.75-3.75M17.25 21 21 17.25"
                  />
                </svg>
              )}
              {minimized && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4 cursor-pointer hover:text-[#4ade80]"
                  onClick={() => {
                    setMinimized(false);
                  }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 4.5h14.25M3 9h9.75M3 13.5h5.25m5.25-.75L17.25 9m0 0L21 12.75M17.25 9v12"
                  />
                </svg>
              )}
              <svg
                onClick={() => {
                  setStatus(false);
                  setTimeout(() => {
                    onClose();
                  }, 100);
                }}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4 cursor-pointer hover:text-[#4ade80]"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </div>
          </div>

          {minimized === false && (
            <div className="PlayerStatBox flex-col max-h-[280px] overflow-y-hidden hover:overflow-y-scroll">
              <div className="flex-col p-2 border-b">
                <div className="flex py-1">
                  Goals
                  <span className="ml-auto">
                    {processedEvent?.[optaEvents?.[0]?.contestantId]
                      ?.goals_total ?? 0}
                  </span>
                </div>
                <div className="flex py-1">
                  Assists
                  <span className="ml-auto">
                    {processedTotalEvent?.assist_events?.filter(
                      (ev) => ev.playerId === player?.opta_ID
                    )?.length ?? 0}
                  </span>
                </div>
              </div>
              <div className="flex-col p-2 border-b">
                <div className="flex py-1">
                  Touches
                  <span className="ml-auto">
                    {processedEvent?.[optaEvents?.[0]?.contestantId]
                      ?.touches_total ?? 0}
                  </span>
                </div>
                <div className="flex py-1">
                  Accurate passes
                  <span className="ml-auto">
                    {processedEvent?.[optaEvents?.[0]?.contestantId]
                      ?.pass_success ?? 0}
                    /
                    {processedEvent?.[optaEvents?.[0]?.contestantId]
                      ?.pass_total ?? 0}
                  </span>
                </div>
                <div className="flex py-1">
                  Passes in final 3rd
                  <span className="ml-auto">
                    {processedEvent?.[optaEvents?.[0]?.contestantId]
                      ?.pass_into_final_3rd_success ?? 0}
                    /
                    {processedEvent?.[optaEvents?.[0]?.contestantId]
                      ?.pass_into_final_3rd ?? 0}
                  </span>
                </div>
                <div className="flex py-1">
                  Crosses
                  <span className="ml-auto">
                    {processedEvent?.[optaEvents?.[0]?.contestantId]
                      ?.crosses_total ?? 0}
                  </span>
                </div>
              </div>
              <div className="flex-col p-2 border-b">
                <div className="flex py-1">
                  Shots
                  <span className="ml-auto">
                    {processedEvent?.[optaEvents?.[0]?.contestantId]
                      ?.shots_total ?? 0}
                  </span>
                </div>
                <div className="flex py-1">
                  Shots on target
                  <span className="ml-auto">
                    {processedEvent?.[optaEvents?.[0]?.contestantId]
                      ?.shots_on_target ?? 0}
                  </span>
                </div>
                <div className="flex py-1">
                  Shots inside box
                  <span className="ml-auto">
                    {processedEvent?.[optaEvents?.[0]?.contestantId]
                      ?.shots_inside_box ?? 0}
                  </span>
                </div>
                <div className="flex py-1">
                  Hit woodwork
                  <span className="ml-auto">
                    {processedEvent?.[optaEvents?.[0]?.contestantId]
                      ?.shots_hit_woodwork ?? 0}
                  </span>
                </div>
                <div className="flex py-1">
                  Shots blocked
                  <span className="ml-auto">
                    {processedEvent?.[optaEvents?.[0]?.contestantId]
                      ?.shots_blocked ?? 0}
                  </span>
                </div>
              </div>
              <div className="flex-col p-2 border-b">
                <div className="flex py-1">
                  Clearances
                  <span className="ml-auto">
                    {processedEvent?.[optaEvents?.[0]?.contestantId]
                      ?.clearance_total ?? 0}
                  </span>
                </div>
                <div className="flex py-1">
                  Interceptions
                  <span className="ml-auto">
                    {processedEvent?.[optaEvents?.[0]?.contestantId]
                      ?.interception_total ?? 0}
                  </span>
                </div>
                <div className="flex py-1">
                  Total tackles
                  <span className="ml-auto">
                    {processedEvent?.[optaEvents?.[0]?.contestantId]
                      ?.tackles_total ?? 0}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </Panel>
    </div>
  );
}

export default PlayerStats;
