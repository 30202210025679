import React, { useEffect, useState } from "react";
import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisHorizontalIcon,
  MapPinIcon,
} from "@heroicons/react/20/solid";

import moment, * as Moment from "moment";
import { extendMoment } from "moment-range";
import { classNames } from "../../../Utils/Utils";

export default function CalendarUI({
  onChangeDate,
  date,
  onActiveStartDateChange,
  highlights,
}) {
  const [days, setDays] = useState([]);
  const [month, setMonth] = useState(moment().month());
  const [year, setYear] = useState(moment().year());
  const [selectedDay, setSelectedDay] = useState(moment());

  useEffect(() => {
    setDays(getDaysInAMonth({ month, year, selectedDay, highlights }));
  }, [month, year, selectedDay, highlights]);

  useEffect(() => {
    setMonth(moment(date)?.month());
    setYear(moment(date)?.year());
  }, [date]);

  function getDaysInAMonth({ month, year, selectedDay, highlights }) {
    const moment = extendMoment(Moment);
    const startDate = moment([year, month]);

    const firstDay = moment(startDate).startOf("month");
    const endDay = moment(startDate).endOf("month");

    const monthRange = moment.range(firstDay, endDay);
    const weeks = [];
    const days = Array.from(monthRange.by("day"));
    if (days[0].day() === 0) {
      weeks.push(days[0].week() - 1);
    }
    days.forEach((it) => {
      if (!weeks.includes(it.week())) {
        weeks.push(it.week());
      }
    });

    const calendar = [];
    weeks.forEach((week) => {
      const firstWeekDay = moment().week(week).day(1);
      const lastWeekDay = moment().week(week).day(7);

      const weekRange = moment.range(firstWeekDay, lastWeekDay);
      calendar.push(Array.from(weekRange.by("day")));
    });
    const days_ = [];
    calendar.forEach((week) => {
      week.forEach((day) => {
        days_.push({
          date: day?.format("YYYY-MM-DD"),
          isCurrentMonth: day.month() === month,
          isToday: day?.format("YYYY-MM-DD") === moment()?.format("YYYY-MM-DD"),
          isSelected:
            selectedDay?.format("YYYY-MM-DD") === day?.format("YYYY-MM-DD"),
          isHighlighted:
            highlights?.findIndex(
              (h) => h === day?.format("YYYY-MM-DD") + "Z"
            ) > -1,
        });
      });
    });
    // console.log(days_);
    return days_;
  }

  return (
    <div className="mt-10 text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 lg:mt-9 xl:col-start-9">
      <div className="flex items-center text-gray-900">
        <button
          type="button"
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          onClick={() => {
            let new_date = date.subtract(1, "month");

            setMonth(new_date?.month());
            setYear(new_date?.year());
            onChangeDate(new_date);
          }}
        >
          <span className="sr-only">Previous month</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <div className="flex-auto text-sm font-semibold">
          {date?.format("MMMM")}
        </div>
        <button
          type="button"
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          onClick={() => {
            let new_date = date.add(1, "month");

            setMonth(new_date?.month());
            setYear(new_date?.year());
            onChangeDate(new_date);
          }}
        >
          <span className="sr-only">Next month</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
        <div>M</div>
        <div>T</div>
        <div>W</div>
        <div>T</div>
        <div>F</div>
        <div>S</div>
        <div>S</div>
      </div>
      <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
        {days.map((day, dayIdx) => (
          <button
            key={day.date}
            type="button"
            className={classNames(
              "py-1.5 hover:bg-gray-100 focus:z-10",
              day.isCurrentMonth && !day.isHighlighted
                ? "bg-white"
                : "bg-gray-50",
              (day.isSelected || day.isToday) && "font-semibold",
              day.isSelected && "text-white",
              !day.isSelected &&
                day.isCurrentMonth &&
                !day.isToday &&
                "text-gray-900",
              !day.isSelected &&
                !day.isCurrentMonth &&
                !day.isToday &&
                "text-gray-400",
              day.isToday && !day.isSelected && "text-green-600",
              dayIdx === 0 && "rounded-tl-lg",
              dayIdx === 6 && "rounded-tr-lg",
              dayIdx === days.length - 7 && "rounded-bl-lg",
              dayIdx === days.length - 1 && "rounded-br-lg",
              day.isHighlighted && "bg-green-200"
            )}
            onClick={() => {
              setSelectedDay(moment(day.date));
              onChangeDate(day.date);
            }}
          >
            <time
              dateTime={day.date}
              className={classNames(
                "mx-auto flex h-7 w-7 items-center justify-center rounded-full",
                day.isSelected && day.isToday && "bg-green-600",
                day.isSelected && !day.isToday && "bg-green-600"
              )}
            >
              {day.date.split("-").pop().replace(/^0/, "")}
            </time>
          </button>
        ))}
      </div>
    </div>
  );
}
